import clsx from "clsx"
import { useEffect, useRef } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ApiService } from "../../../theme/helpers"
import { InputTime } from "../../../theme/partials/widgets/form/InputTime"
import { popupMessage } from "../../modules/messages"

export default function WeeklyTimingCard({ merchantid, storetierid, ordertypes, setStoreTier }: any) {
    const { t } = useTranslation()
    const { watch, setValue, register, control, reset, handleSubmit, formState: { isSubmitting } } = useForm({
        defaultValues: {
            ordertypes: [
                {
                    name: 'Delivery',
                    weeklytimings: [
                        { name: 'mon', status: true, starttime: '00:00', endtime: '00:00' },
                        { name: 'tue', status: false, starttime: null, endtime: null },
                        { name: 'wed', status: false, starttime: null, endtime: null },
                        { name: 'thu', status: false, starttime: null, endtime: null },
                        { name: 'fri', status: false, starttime: null, endtime: null },
                        { name: 'sat', status: false, starttime: null, endtime: null },
                        { name: 'sun', status: false, starttime: null, endtime: null },
                    ]
                }
            ]
        }
    })
    const btnRef = useRef<HTMLButtonElement | null>(null)

    useEffect(() => {
        reset({ ordertypes: ordertypes || [] })
    }, [ordertypes])

    async function onSubmit(data: any) {
        if (!storetierid) {
            popupMessage({ icon: 'error', autoClose: true })
            return;
        }
        try {
            const result = await ApiService.put(`/settings/storetiers/${storetierid}`, { ordertypes: data.ordertypes })
            setStoreTier(result.data)
            popupMessage({ icon: 'success', autoClose: true })
        } catch (error) {
            popupMessage({ icon: 'error', autoClose: true })
        }
    }

    function isChecked(name: string) {
        const orderTypes = watch("ordertypes")
        const item = orderTypes.find((item: any) => item.name === name)
        return !!item && item.weeklytimings.some((day: any) => day.status === true)
    }

    function onCheckAll(name: string, checked: boolean) {
        const orderTypes = watch("ordertypes")
        const index = orderTypes.findIndex((item: any) => item.name === name)
        if (index > -1) {
            orderTypes[index].weeklytimings.forEach((_, index2) => {
                let item = orderTypes[index].weeklytimings[index2]
                item.status = checked
                if (checked) {
                    item.starttime = checked ? (item.starttime || '07:00') : null
                    item.endtime = checked ? (item.endtime || '23:00') : null
                }

                setValue(`ordertypes.${index}.weeklytimings.${index2}`, item)
            })
        }
    }

    return <>
        <div className="d-flex align-items-center justify-content-between mb-5">
            <div className="fs-4 fw-bold">Weekly Timings</div>
            <button disabled={isSubmitting} className="btn btn-sm btn-primary" onClick={() => btnRef.current?.click()}>Save Change</button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <button ref={btnRef} type="submit" className="d-none" />
            <table className="table text-center table-bordered m-0">
                <thead>
                    <tr className="bg-orange bg-opacity-25 text-uppercase">
                        <th scope="col">
                            {t('text.order-type')}
                        </th>
                        <th scope="col">
                            {t('text.monday')}
                        </th>
                        <th scope="col">
                            {t('text.tuesday')}
                        </th>
                        <th scope="col">
                            {t('text.wednesday')}
                        </th>
                        <th scope="col">
                            {t('text.thursday')}
                        </th>
                        <th scope="col">
                            {t('text.friday')}
                        </th>
                        <th scope="col">
                            {t('text.saturday')}
                        </th>
                        <th scope="col">
                            {t('text.sunday')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {watch("ordertypes").map((item, ind) => <tr key={ind}>
                        <td className="bg-orange bg-opacity-25 fw-bold">
                            <div className="d-flex flex-column">
                                <label className="form-check form-switch form-switch-sm form-check-custom mb-2 mx-auto">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={isChecked(item.name)}
                                        onChange={(e) => {
                                            onCheckAll(item.name, e.target.checked)
                                        }}
                                    />
                                </label>
                                <span className="mw-125px w-125px text-truncate py-2">{item.name}</span>
                            </div>
                        </td>
                        {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, index) => {

                            return <td key={index} className="py-3">
                                <div className="d-flex flex-column text-lowercase">
                                    <input
                                        className="d-none"
                                        {...register(`ordertypes.${ind}.weeklytimings.${index}.name`)}
                                        value={day}
                                    />
                                    <label className="form-check form-switch form-switch-sm form-check-custom mb-2 mx-auto">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            {...register(
                                                `ordertypes.${ind}.weeklytimings.${index}.status`,
                                                {
                                                    onChange(event) {
                                                        if (!event.target.checked) {
                                                            setValue(`ordertypes.${ind}.weeklytimings.${index}.starttime`, null)
                                                            setValue(`ordertypes.${ind}.weeklytimings.${index}.endtime`, null)
                                                        } else {
                                                            setValue(`ordertypes.${ind}.weeklytimings.${index}.starttime`, '07:00')
                                                            setValue(`ordertypes.${ind}.weeklytimings.${index}.endtime`, '23:00')
                                                        }
                                                        return event
                                                    },
                                                }
                                            )}
                                        />
                                    </label>
                                    <div id={`is${ind}/${index}`} className="d-flex flex-center flex-fill wrapper-forcus-times">
                                        <Controller
                                            name={`ordertypes.${ind}.weeklytimings.${index}.starttime`}
                                            control={control}
                                            render={({ field: { value, onChange } }) => <InputTime
                                                disabled={!watch(`ordertypes.${ind}.weeklytimings.${index}.status`)}
                                                className="text-center bg-transparent form-control-sm px-0 border-white min-h-20px"
                                                value={value as any}
                                                onChange={onChange}
                                                onFocus={(e) => {
                                                    document.getElementById(`is${ind}/${index}`)?.classList.add('type1')
                                                }}
                                                onBlur={(e) => {
                                                    document.getElementById(`is${ind}/${index}`)?.classList.remove('type1')
                                                }}
                                            />}
                                        />
                                        <div className={clsx({ 'd-none': !watch(`ordertypes.${ind}.weeklytimings.${index}.status`) })}>-</div>
                                        <Controller
                                            name={`ordertypes.${ind}.weeklytimings.${index}.endtime`}
                                            control={control}
                                            render={({ field: { value, onChange } }) => <InputTime
                                                disabled={!watch(`ordertypes.${ind}.weeklytimings.${index}.status`)}
                                                className="text-center bg-transparent form-control-sm px-0 border-white min-h-20px"
                                                value={value as any}
                                                onChange={onChange}
                                                onFocus={(e) => {
                                                    document.getElementById(`is${ind}/${index}`)?.classList.add('type2')
                                                }}
                                                onBlur={(e) => {
                                                    document.getElementById(`is${ind}/${index}`)?.classList.remove('type2')
                                                }}
                                            />}
                                        />
                                    </div>
                                </div>
                            </td>
                        })}
                    </tr>)}
                </tbody>
            </table>
        </form>
    </>
}