import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react";
import { Modal, Image } from "react-bootstrap"
import * as yup from "yup";
import { BasicCell, CustomHeader, FilterDropdown, FormSubmit, InputSelect, KTButton, KTFormItem, KTTable, RowIcon } from "../../../theme/partials";
import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";
import { IInitField } from "../../modules/api";
import { ApiDeleteFile, ApiService, ApiShowError, ApiUploadFiles, formatOptions, sortWithAny } from "../../../theme/helpers";
import { Column } from "react-table";
import { popupMessage } from "../../modules/messages";
import QRCode from "qrcode"
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js";

export interface IHospDiningTable extends IInitField {
    merchantid: string | null
    sectionid: string | null
    sectioncode: string | null
    tableno: string | null
    tablename: string | null
    seatcapacity: number | null
    mincapacity: number | null
    maxcapacity: number | null
    qrcodeordertype: string | null
    qrcodetype: string | null
    expirationtime: number | null
    qrexpireat: string | null
    qrcodethumb: string | null
    qrcode: string | null
    active: boolean
    diningtablestatus?: string | null
    salestype?: string | null
}

export interface IConfigStoreTier extends IInitField {
    code: string | null
    name: string | null
    description: string | null
    active: boolean
    ordertypes: {
        code: string | null
        name: string | null
        minimumqty: number | null
        minimumamount: number | null
        leadtimeminutes: number | null
        assigns: {
            categoryid: string | null
            subcategories: Array<{ id: string, code: string, name: string }>
            items: string[]
        }[],
        weeklytimings: {
            status: boolean
            name: any
            starttime: any
            endtime: any
        }[]
    }[]
    recommendeditems: any[]
    featureditems: any[]
    isrecommended: boolean
    isfeatured: boolean
}

const optionQrType = [
    { value: "static", label: "Static" },
    { value: "dynamic", label: "Dynamic" },
]

function printImage(url: string) {
    const newWindow = window.open("", "_blank");
    if (!newWindow) return popupMessage({ icon: 'error', description: 'Please allow popups for this website' })
    newWindow.document.write(`
        <html>
        <head>
            <title>Print Image</title>
        </head>
        <body style="text-align: center;">
            <img src="${url}" style="max-width: 100%; height: auto;">
            <script>
                window.onload = function() {
                    window.print();
                    setTimeout(() => window.close(), 500);
                };
            </script>
        </body>
        </html>
    `);
    newWindow.document.close();
}

export function generateSecureShortCode(id: string) {
    const salt = CryptoJS.lib.WordArray.random(4).toString(CryptoJS.enc.Hex); // Tạo salt 4 byte
    if (!process.env.REACT_APP_SECRET_KEY) {
        return { hash: id, salt };
    }
    const rawString = `${id}!${salt}`;
    const hash = CryptoJS.HmacSHA256(rawString, process.env.REACT_APP_SECRET_KEY as string).toString(CryptoJS.enc.Hex);
    return { hash, salt };
}

export async function generateQRCode(code: string, size?: number) {
    try {
        return await QRCode.toDataURL(code, { width: size || 100 })
    } catch (error) {
        console.log(error)
    }
}

export function downloadFile(dataUrl: string, fileName: string) {
    if (!dataUrl) return popupMessage({ icon: 'error', description: 'Download failed' })
    var a = document.createElement("a");
    a.href = dataUrl;
    a.download = `${fileName}.png`;
    a.click();
}

export function base64ToFile(base64String: string, fileName?: string) {
    const radom = Math.floor(Math.random() * 100000);
    const mimeType = base64String.split(';')[0].split(':')[1]
    const name = fileName || `img_${radom}${Date.now()}.${mimeType.split('/')[1]}`

    // Split the base64 string to get the data part
    const base64Data = base64String.split(';base64,')[1];

    // Convert the base64 data to binary data
    const binaryData = atob(base64Data);

    // Create an array to hold the binary data
    const dataArray = new Uint8Array(binaryData.length);

    // Populate the array with binary data
    for (let i = 0; i < binaryData.length; i++) {
        dataArray[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the binary data
    const blob = new Blob([dataArray], { type: mimeType || 'application/octet-stream' });

    // Create a File from the Blob
    const file = new File([blob], name, { type: mimeType || 'application/octet-stream' });

    return file;
}

export default function QrCodeCard({ merchantid, enableordertype, ordertypes }: any) {
    const { t } = useTranslation()
    const optionOrderType = formatOptions(ordertypes || [], ['code', 'name'])
    const { data: dataTables, isSuccess: isTables, isFetching: loadTables } = useQuery(
        [`fnb/hospdiningtables?merchantid=${merchantid}&select=qrcodethumb`],
        () => ApiService(`/fnb/hospdiningtables?merchantid=${merchantid}&select=qrcodethumb`).then(res => res.data),
        { enabled: !!merchantid }
    )
    const [tables, setTables] = useState<IHospDiningTable[]>([])
    const [valueTable, setValueTable] = useState<IHospDiningTable | undefined>()
    const optionTables = isTables ? formatOptions(dataTables, ['tableno', 'tablename']) : []
    const [previewImage, setPreviewImage] = useState<IHospDiningTable | undefined>()

    useEffect(() => {
        if (!loadTables && isTables) {
            setTables(sortWithAny(dataTables.filter((f: any) => f.qrcodethumb), { order: "desc", sort: "tableno" }).reverse())
        } else {
            setTables([])
        }
    }, [loadTables])

    const columnsTable: Array<Column<IHospDiningTable>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='Order Type' />,
        accessor: 'qrcodeordertype',
        Cell: ({ ...props }) => <BasicCell data={optionOrderType.find(f => f.value == props.data[props.row.index].qrcodeordertype)?.label || ''} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Type' />,
        accessor: 'qrcodetype',
        Cell: ({ ...props }) => <BasicCell data={optionQrType.find(f => f.value == props.data[props.row.index].qrcodetype)?.label || ''} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Table ID' />,
        accessor: 'tableno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].tableno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Seat Capacity' />,
        accessor: 'seatcapacity',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].seatcapacity} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Expires At' />,
        accessor: 'qrexpireat',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].qrexpireat} type="datetime" />
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Qr Code' className="text-center" />,
        accessor: 'qrcodethumb',
        Cell: ({ ...props }) => <BasicCell
            data={props.data[props.row.index].qrcodethumb}
            type="img"
            className="d-flex flex-center"
            onClick={() => setPreviewImage(props.data[props.row.index])} />
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Active' />,
        accessor: 'active',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].active} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="print" onClick={async () => {
                const res = await ApiService(`/fnb/hospdiningtables/${props.data[props.row.index].id}?select=qrcode`);
                const table = res.data
                if (!table.qrcode) return popupMessage({ icon: 'error', description: 'QR Code has not been generated yet' })
                printImage(table.qrcode)
            }} />
            <RowIcon action="download" onClick={async () => {
                const res = await ApiService(`/fnb/hospdiningtables/${props.data[props.row.index].id}?select=qrcode`);
                const table = res.data
                if (!table.qrcode) return popupMessage({ icon: 'error', description: 'QR Code has not been generated yet' })
                downloadFile(table.qrcode, `table-${props.data[props.row.index].tableno}`)
            }} />
            <RowIcon action="reset" onClick={async () => {
                const { id, qrcodetype, qrcodethumb, qrcode } = props.data[props.row.index]
                try {
                    if (qrcodethumb) {
                        await ApiDeleteFile(`QRCODE${String(qrcodethumb).split('/QRCODE').at(1)}`)
                    }
                    if (qrcode) {
                        await ApiDeleteFile(`QRCODE${String(qrcode).split('/QRCODE').at(1)}`)
                    }
                } catch (error) { }
                if (qrcodetype == 'dynamic') {
                    setValueTable({
                        ...props.data[props.row.index]
                    })
                } else {
                    try {
                        const bodyData = await createdQRCode(props.data[props.row.index])
                        const result = await ApiService.put(`/fnb/hospdiningtables/${id}`, bodyData)
                        popupMessage({ icon: 'success', autoClose: true })
                        setTables(tables.map(m => m.id == id ? result.data : m))
                    } catch (error) {
                        ApiShowError(error)
                    }
                }
            }} />
            <RowIcon action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                const bodyData = {
                    id: props.data[props.row.index].id,
                    qrcodeordertype: null,
                    qrcodetype: null,
                    expirationtime: null,
                    qrexpireat: null,
                    qrcodethumb: null,
                    qrcode: null,
                }
                try {
                    if (props.data[props.row.index].qrcodethumb) {
                        const pathtofile = props.data[props.row.index].qrcodethumb
                        await ApiDeleteFile(`QRCODE${String(pathtofile).split('/QRCODE').at(1)}`)
                    }
                    if (props.data[props.row.index].qrcode) {
                        const pathtofile = props.data[props.row.index].qrcode
                        await ApiDeleteFile(`QRCODE${String(pathtofile).split('/QRCODE').at(1)}`)
                    }
                } catch (error) { }
                try {
                    await ApiService.put(`/fnb/hospdiningtables/${id}`, bodyData)
                    popupMessage({ icon: 'success', autoClose: true })
                    setTables(tables.filter(f => f.id != id))
                } catch (error) {
                    ApiShowError(error)
                }
            }} />
        </div>
    }]

    function addMinutes(date: Date, minutes: number) {
        date.setMinutes(date.getMinutes() + minutes)
        return date
    }

    async function createdQRCode(value: IHospDiningTable) {
        const valueTable = optionTables.find(f => f.tableno == value.tableno)
        if (!valueTable) return undefined
        const newDate = new Date()
        const expirationtime = value.expirationtime ? Number(value.expirationtime) : 0
        const qrexpireat = value.qrcodetype == 'dynamic' ? addMinutes(newDate, expirationtime) : null
        const { hash, salt } = generateSecureShortCode(valueTable.id)
        const createdcode = `${process.env.REACT_APP_ORDER_URL}/scan/` + hash

        const image_qrcode = await generateQRCode(createdcode, 500)
        const image_qrcodethumb = await generateQRCode(createdcode)
        const file_qrcode = base64ToFile(image_qrcode as string, 'qrcode.png')
        const file_qrcodethumb = base64ToFile(image_qrcodethumb as string, 'qrcodethumb.png')
        const uploadFiels = await ApiUploadFiles(value.id as string, 'QRCODE', [file_qrcode, file_qrcodethumb])

        return {
            id: valueTable.id,
            merchantid: merchantid,
            qrcodeordertype: value.qrcodeordertype,
            qrcodetype: value.qrcodetype,
            expirationtime: expirationtime,
            qrexpireat: qrexpireat,
            qrcodethumb: uploadFiels.find((f: any) => f.fileName == 'qrcodethumb.png')?.url,
            qrcode: uploadFiels.find((f: any) => f.fileName == 'qrcode.png')?.url,
            scancode: hash
        }
    }

    const [filter, setFilter] = useState<IHospDiningTable | undefined>()
    const { register, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: IHospDiningTable[]) => {
        if (!filter) return data
        return data.filter(f => {
            const checks = [
                f.active == filter.active,
            ]
            return checks.every(val => val == true)
        })
    }

    return <>
        <KTTable
            toolbar={<>
                <FilterDropdown
                    handleSubmit={handleSubmit}
                    onClose={() => {
                        setFilter(undefined)
                        reset({})
                    }}
                    onSubmit={(data) => setFilter(data)}>
                    <KTFormItem title='Active' labelWidth="auto">
                        <label className="form-check form-switch form-check-custom">
                            <input className="form-check-input" type="checkbox" {...register("active")} />
                        </label>
                    </KTFormItem>
                </FilterDropdown>
                {/* <KTButton action="generate" disabled={!merchantid}
                    titleBtn={'Generate Qr Code'}
                    onClick={() => {
                        if (!enableordertype || enableordertype?.length == 0) {
                            return popupMessage({ icon: "error", description: "Enable order type not found." })
                        }
                        setValueTable({
                            merchantid: null,
                            sectionid: null,
                            sectioncode: null,
                            tableno: null,
                            tablename: null,
                            seatcapacity: null,
                            mincapacity: null,
                            maxcapacity: null,
                            qrcodeordertype: 'dine-in',
                            qrcodetype: null,
                            expirationtime: null,
                            qrexpireat: null,
                            qrcodethumb: null,
                            qrcode: null,
                            active: true
                        })
                    }} /> */}
                <KTButton action="new" disabled={!merchantid} onClick={() => {
                    if (!enableordertype || enableordertype?.length == 0) {
                        return popupMessage({ icon: "error", description: "Enable order type not found." })
                    }
                    setValueTable({
                        merchantid: null,
                        sectionid: null,
                        sectioncode: null,
                        tableno: null,
                        tablename: null,
                        seatcapacity: null,
                        mincapacity: null,
                        maxcapacity: null,
                        qrcodeordertype: enableordertype[0].code,
                        qrcodetype: null,
                        expirationtime: null,
                        qrexpireat: null,
                        qrcodethumb: null,
                        qrcode: null,
                        active: false
                    })
                }} />
            </>}
            columns={columnsTable}
            data={onFilter(tables)}
            pagination
            search
        />
        {previewImage && <ModalPrivewQRCode
            {...{ previewImage, setPreviewImage }}
        />}
        {valueTable && <ModalQRCode
            key={'ModalQRCode'}
            {...{
                optionTables: optionTables.filter(f => !tables.map(m => m.id).includes(f.id)),
                valueData: valueTable,
                enableordertype: enableordertype,
                handleClose: function () {
                    setValueTable(undefined)
                },
                handleSave: async function (values: IHospDiningTable) {
                    if (!!values.id && values.qrcodetype == 'dynamic') {
                        const { qrcodeordertype, qrcodetype, expirationtime } = values
                        const bodyData = await createdQRCode({ ...values, qrcodeordertype, qrcodetype, expirationtime });
                        const result = await ApiService.put(`/fnb/hospdiningtables/${values.id}`, bodyData)
                        popupMessage({ icon: 'success', autoClose: true })
                        setTables(tables.map(m => m.id == values.id ? result.data : m))
                        setValueTable(undefined)
                        return;
                    }
                    try {
                        if (values.active) {
                            const { qrcodeordertype, qrcodetype, expirationtime } = values
                            const bodyDatas = await Promise.all(optionTables.map(async (item): Promise<any> => {
                                return await createdQRCode({ ...item, qrcodeordertype, qrcodetype, expirationtime });
                            }));
                            const results = await ApiService.post(`/fnb/hospdiningtables/upsertmulti`, bodyDatas)
                            popupMessage({ icon: 'success', autoClose: true })
                            setTables([...results.data.createdItems, ...results.data.updatedItems])
                        } else {
                            const bodyData = await createdQRCode(values)
                            if (bodyData) {
                                const result = await ApiService.put(`/fnb/hospdiningtables/${values.id}`, bodyData)
                                popupMessage({ icon: 'success', autoClose: true })
                                setTables([result.data, ...tables])
                            }
                        }
                    } catch (error) {
                        ApiShowError(error)
                    }
                    setValueTable(undefined)
                }
            }}
        />}
    </>
}

function ModalPrivewQRCode({ previewImage, setPreviewImage }: any) {
    const { data: dataTable } = useQuery(
        [`/fnb/hospdiningtables/${previewImage.id}?select=qrcode`],
        () => ApiService(`/fnb/hospdiningtables/${previewImage.id}?select=qrcode`).then(res => res.data),
        { enabled: !!previewImage?.id }
    )

    return <Modal show={previewImage != undefined} onHide={() => setPreviewImage(undefined)} centered>
        <Modal.Body className="p-0">
            <Modal.Header closeButton className="pb-0 border-0">
                <Modal.Title>{`${dataTable?.tablename}`}</Modal.Title>
            </Modal.Header>
            {dataTable?.qrcode && <Image src={dataTable?.qrcode || '#'} alt={'previewimage'} fluid className="rounded" />}
        </Modal.Body>
    </Modal>
}

function ModalQRCode({ valueData, handleClose, handleSave, optionTables, enableordertype }: any) {
    const isGenerate = valueData?.active
    const { register, control, handleSubmit, watch, reset, setValue, formState: { errors, isSubmitting } } = useForm<IHospDiningTable>({
        resolver: yupResolver(yup.object().shape({
            tableno: yup.string().test('check', (val, ctx) => {
                if (!val && !isGenerate) return ctx.createError()
                return true
            }).nullable(true).transform(val => val || null),
            qrcodeordertype: yup.string().required(),
            qrcodetype: yup.string().required(),
            expirationtime: yup.number()
                .test('require_by_type', (val, ctx) => {
                    if (!val && ctx.parent['qrcodetype'] == 'dynamic') return ctx.createError()
                    return true
                })
                .nullable(true).transform(val => val ? Number(val) : null)
        }))
    })

    useEffect(() => {
        if (valueData) reset(valueData)
    }, [valueData])

    return <Modal show={valueData != undefined} onHide={handleClose} centered>
        <Modal.Header closeButton>
            <Modal.Title>{!!valueData?.id ? 'Edit' : isGenerate ? 'Generate' : 'Add'} QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(handleSave)}>
                <KTFormItem title="Order Type" isHeight>
                    <div className="d-flex flex-wrap gap-2 w-100">
                        {Array.isArray(enableordertype) && enableordertype.map((m: any) => <label key={m.code} className="form-check form-check-sm form-check-custom gap-1">
                            <input className="form-check-input" type="radio" value={m.code} {...register('qrcodeordertype')} />
                            <span className="text-nowrap">{m.name}</span>
                        </label>)}
                    </div>
                </KTFormItem>
                {!isGenerate && <KTFormItem title="Select Table">
                    <Controller
                        control={control}
                        name='tableno'
                        render={({ field: { value, onChange } }) => <InputSelect
                            options={optionTables}
                            value={optionTables.find((f: any) => f.value == value) || null}
                            onChange={(e) => {
                                if (!e) return onChange(null)
                                onChange(e.value)
                                setValue('id', e.id)
                            }}
                            className={clsx('w-100', { 'form-error': errors.tableno })}
                        />}
                    />
                </KTFormItem>}
                <KTFormItem title="QR Code Type">
                    <Controller
                        control={control}
                        name='qrcodetype'
                        render={({ field: { value, onChange } }) => <InputSelect
                            options={optionQrType}
                            value={optionQrType.find(f => f.value == value) || null}
                            onChange={(e) => {
                                if (!e) return onChange(null)
                                onChange(e.value)
                            }}
                            className={clsx('w-100', { 'form-error': errors.qrcodetype })}
                        />}
                    />
                </KTFormItem>
                <KTFormItem title="Expiration time (min.)">
                    <input disabled={watch('qrcodetype') != 'dynamic'} className={clsx("form-control form-control-sm", { "form-error": errors.expirationtime })} {...register("expirationtime")} />
                </KTFormItem>
                {isGenerate && <span className="text-gray-600">
                    Kindly be aware that carrying out this action will result in the replacement of the current data on the table.
                </span>}
                <div className="mt-6 d-flex flex-end">
                    <FormSubmit
                        type={true}
                        isSubmitting={isSubmitting}
                        handleClose={handleClose}
                    />
                </div>
            </form>
        </Modal.Body>
    </Modal >
}