import localforage from "localforage";
import { popupAlert, popupMessage } from "../../../modules/messages";
import { KITCHENSTATUS, renderItemProduction } from "../../orderManagement/tabs/templateOrder";
import { kitchenPrintEpson, orderPrintEpson, prePrintEpson } from "./templatePrint";
import { ApiService, sortByNumber, unique_arr } from "../../../../theme/helpers";
import Swal from "sweetalert2";
import { t } from "i18next";

export const connectPrinterWithTimeout = (dataPrint: any, timeout = 10000) => {
  return new Promise(async(resolve, reject) => {
      let ePosDev = new (window as any).epson.ePOSDevice();
      let {host, port} = dataPrint

      // Thiết lập timeout
      const timer = setTimeout(() => {
          ePosDev.disconnect(); // Ngắt kết nối nếu quá thời gian chờ
          reject(new Error('Timeout'));
      }, timeout);
      await ePosDev.connect(host, port, (data: any) => {
        // console.log('data',data)
        if (data === "OK" || data === 'SSL_CONNECT_OK') {
          ePosDev.createDevice(
            "local_printer",
            ePosDev.DEVICE_TYPE_PRINTER,
            { crypto: true, buffer: false },
            (devobj: any, retcode: any) => {
              if (retcode === "OK") {
                clearTimeout(timer); // Xóa bỏ timeout khi kết nối thành công
                resolve(devobj); // Trả về object kết nối thành công
    
              } else {
                clearTimeout(timer); // Xóa bỏ timeout khi có lỗi
                reject(new Error(`${retcode}`));
                resolve (retcode);
    
              }
            }
          );
        } else {
          reject(new Error(`${data}`));
          resolve (data);
        }
      });
  });
};
export const isConnectPrinter = async(dataPrint: any) => {
  let ePosDev = new (window as any).epson.ePOSDevice();
  let {host, port} = dataPrint
  // console.log('dataPrint',dataPrint)
  let isCheck = false
  await ePosDev.connect(host, port, (data: any) => {
    // console.log('data',data)
    if (data === "OK" || data === 'SSL_CONNECT_OK') {
      ePosDev.createDevice(
        "local_printer",
        ePosDev.DEVICE_TYPE_PRINTER,
        { crypto: true, buffer: false },
        (devobj: any, retcode: any) => {
          if (retcode === "OK") {
            isCheck = true

          } else {
            console.log('retcode',retcode)

          }
        }
      );
    } else {
      console.log('retcode',data)
    }
  });
  return isCheck
}
export const checkConnectPrinter = (dataPrint: any,index: any,setDataLoading: any) => {
  let ePosDev = new (window as any).epson.ePOSDevice();
  let {host, port, name} = dataPrint
  // console.log('dataPrint',dataPrint)
  ePosDev.connect(host, port, (data: any) => {
    // console.log('data',data)
    if (data === "OK" || data === 'SSL_CONNECT_OK') {
      
      ePosDev.createDevice(
        "local_printer",
        ePosDev.DEVICE_TYPE_PRINTER,
        { crypto: true, buffer: false },
        (devobj: any, retcode: any) => {
          if (retcode === "OK") {
            // printer.current = devobj;
            // let  printer = devobj;
            setDataLoading((dataList: any) => {
              dataList[index].isLoading = false
              dataList[index].returnStatus = 'Successed'
              return dataList
            })
            popupAlert(`PRINTER [${name}] [${host}] connected`, 'success')
            

            // setConnectionStatus(STATUS_CONNECTED);
          } else {
            popupAlert(`PRINTER [${name}] [${host}] ${retcode}`, 'info')
            setDataLoading((dataList: any) => {
              dataList[index].isLoading = false
              dataList[index].returnStatus = 'Failed '+ retcode
      
              return dataList
            })
            // throw retcode;

          }
        }
      );
    } else {
      popupAlert(`PRINTER [${name}] [${host}] ${data}`, 'error')
      setDataLoading((dataList: any) => {
        dataList[index].isLoading = false
        dataList[index].returnStatus = 'Failed ' + data

        return dataList
      })
      console.log(data)
      throw data;
    }
  });
}
const connect = async(dataPrint: any) => {
  let ePosDev = new (window as any).epson.ePOSDevice();
  let {host, port,productionsectioncode,setPrinterlist, PrinterList} = dataPrint
  ePosDev.connect(host, port, (data: any) => {
    // console.log('data',data)
    if (data === "OK" || data === 'SSL_CONNECT_OK') {
      ePosDev.createDevice(
        "local_printer",
        ePosDev.DEVICE_TYPE_PRINTER,
        { crypto: true, buffer: false },
        (devobj: any, retcode: any) => {
          if (retcode === "OK") {
            // printer.current = devobj;
            // let  printer = devobj;
            let dataInfo = {
              printer: devobj,
              ePosDev: ePosDev,
              productionsectioncode: productionsectioncode
            }
            savePrintlist(dataInfo, PrinterList, setPrinterlist)

            // setConnectionStatus(STATUS_CONNECTED);
          } else {
            // throw retcode;
          }
        }
      );
    } else {
      // throw data;
    }
  });
}
export const connectEpson = async(storePrint: any) => {
  let merchantsData = await localforage.getItem('merchantsData')||{} as any
  let {sectiondispstatmappings} = merchantsData
  sectiondispstatmappings.forEach((item: any) => {
    connect({
      host: item.PrinterList?.host,
      port: item.PrinterList?.port,
      productionsectioncode: item.productionsectioncode,
      setPrinterlist: storePrint.setPrinterlist,
      PrinterList: storePrint.PrinterList,
    })
  })
 
  
};
const savePrintlist = async(dataInfo: any, list: any,setPrinterlist: any) => {
  
  let PrinterList = JSON.parse(JSON.stringify(list))||[]
  let index = PrinterList.findIndex((i: any) => i.productionsectioncode == dataInfo.productionsectioncode) 
  if(index > -1) {
    PrinterList[index] = dataInfo
  }
  else {
    PrinterList.push(dataInfo)
  }
  // console.log('PrinterList',PrinterList)
  
  // const storePrint = useStorePrint((state:any) => state)
  setPrinterlist(PrinterList)
  // localforage.setItem('printerList', PrinterList)
}
const funcReturnTempplatePrint = async(dataPrint: any, type: any, printer: any, ePosDev: any) => {
  try {
    
    if(type == 1) {
      orderPrintEpson(printer,ePosDev,dataPrint)
    }
    if(type == 2) {
      let requestIdPrint = await localforage.getItem('requestIdPrint')||[] as any
      let recordId = requestIdPrint.find((i: any) => i.id == dataPrint.printjobid)
      // console.log('recordId',recordId)
      let type = dataPrint.dataInfo?.type
      // console.log('type',type)
      if(!recordId || recordId?.status == 'failed' || type == 'voided' || type == 'transfer' || type == 'change') {
        if(type != 'voided' && type != 'transfer' && type != 'change') {
          let tempData = {
            id: dataPrint.printjobid,
            status: 'pending',
            dataPrint: dataPrint,
            publicKey: dataPrint.publicKey,

          }
          funcAddRequestIdPrint(tempData)
        }
        kitchenPrintEpson(printer,ePosDev,dataPrint)
        // console.log('asssssssssssss')
      }
    }
    else if (type == 3) {
      orderPrintEpson(printer,ePosDev,{
        ...dataPrint,
        type: type
      })
    }
    else if (type == 4) {
      prePrintEpson(printer,ePosDev,dataPrint)
    }
  } catch (error) {
    console.log('error',error)
  }
  
}
const removeLogsPrinter = (logsPrinter: any) => {
  let rank = 7*24*60*60*100 // logs in 7 days need reset
  let temp = logsPrinter.map((i: any,index: number) =>{
    let a = i.split(': #')[0]
    return {
      index: index,
      value: new Date(a).getTime(),
      label: i, 
    }
  })
  temp = sortByNumber(temp,'value')
  // console.log('temp',temp)
  let b = temp[0]?.value + rank
  temp = temp.filter((i: any) => i.value > b)
  // console.log('temp',temp)
  if(temp.length != 0) {
    let c = sortByNumber(temp,'value','DESC')
    return c.map((i: any) => i.label)
  }
  return logsPrinter
}
const setLogPrinter = async(info: any, text: any,isAlert?: any) => {
  let logsPrinter = await localforage.getItem('logsPrinter')||[] as any
  if(logsPrinter.length != 0) {
    logsPrinter = removeLogsPrinter(logsPrinter)
  }
  let time = new Date().toLocaleString('en-GB',{
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
  if(isAlert) {
    popupAlert(`${time}: #${info.hosptransactioncode}[${info.tableno}] [${info.code}] [${info.host}] ${text}`,'error')
  }
  localforage.setItem('logsPrinter', [`${time}: #${info.hosptransactioncode}[${info.tableno}] [${info.code}] [${info.host}] ${text}`].concat(logsPrinter))
  
}
const returnCodePrinterToText = (code: any) => {
  const objectCode = {
    "EPTR_AUTOMATICAL": 'Đã xảy ra lỗi tự động khôi phục.',
    "EPTR_BATTERY_LOW": 'Hết pin.',
    "EPTR_COVER_OPEN":'Nắp mở.',
    "EPTR_CUTTER": 'Đã xảy ra lỗi cắt tự động.',
    "EPTR_MECHANICAL": 'Đã xảy ra lỗi cơ học.',
    "EPTR_REC_EMPTY": 'Hết giấy. Kiểm tra trước khi chế biến',
    "EPTR_UNRECOVERABLE": 'Đã xảy ra lỗi không thể khắc phục.',
    "SchemaError": 'Yêu cầu in XML chứa lỗi cú pháp.',
    "DeviceNotFound": 'Không tìm thấy máy in được chỉ định bởi ID thiết bị.',
    "PrintSystemError": 'Đã xảy ra lỗi với hệ thống in.',
    "EX_BADPORT": 'Đã phát hiện lỗi với cổng giao tiếp.',
    "EX_TIMEOUT": 'Đã hết thời gian chờ in.',
    "EX_SPOOLER": 'Hàng đợi in đã đầy.',
    "JobNotFound": 'Không tồn tại ID công việc được chỉ định.',
    "Printing": 'Đang in',
    "TooManyRequests": 'Số lượng lệnh in gửi đến máy in đã vượt quá giới hạn cho phép',
    "RequestEntityTooLarge": 'Kích thước của lệnh in vượt quá dung lượng của máy in.',
    "JobSpooling": 'Công việc đang được đưa vào hàng đợi.',
    "ERROR_WAIT_EJECT": 'Đang chờ lấy giấy ra',
  } as any
  if(objectCode[`${code}`]) {
    return objectCode[`${code}`]
  }
  return code
}
export const connectPrintEpson = async(dataEpson: any,setPrinterlist: any) => {
  let dataPrint = dataEpson.data
  let PrinterList = dataEpson.PrinterList||[]
  let type = dataEpson.type
  let printerPort = dataEpson.printConfig?.port
  let printerIPAddress = dataEpson.printConfig?.host //'10.11.4.101'//

  let ePosDevice = {} as any;
  let printer = {} as any;
  let tempDataLog = {
    code: dataPrint.productionsectioncode,
    ...dataEpson.printConfig,
    hosptransactioncode: dataPrint.code,
    tableno: dataPrint.tableno

  }
  if (!printerIPAddress) {
    setLogPrinter(tempDataLog,'IP address not found',true)
    // setConnectionStatus("Type the printer IP address");

    return false;
  }
  if (!printerPort) {
    // setConnectionStatus("Type the printer port");
    setLogPrinter(tempDataLog,'PORT not found',true)
    
    return false;
  }

  setLogPrinter(tempDataLog,'Connecting ...')

  let ePosDev = new (window as any).epson.ePOSDevice();
  
  ePosDevice.current = ePosDev;

  ePosDev.connect(printerIPAddress, printerPort, (data: any) => {
    // console.log('data',data)
    if (data === "OK" || data === 'SSL_CONNECT_OK') {
      setLogPrinter(tempDataLog,'Send')
      ePosDev.createDevice(
        "local_printer",
        ePosDev.DEVICE_TYPE_PRINTER,
        { crypto: false, buffer: false },
        async (devobj: any, retcode: any) => {
          if(devobj) {
            
            devobj.onreceive = async function (response: any) {
              let requestIdPrint = await localforage.getItem('requestIdPrint')||[] as any
              console.log('response',response)
              let printjobid = response.printjobid
              let index = requestIdPrint.findIndex((i: any) => i.id == printjobid)
              if (response?.success) {
                if(index > -1) {
                  requestIdPrint[index].status = 'completed'
                }
                setLogPrinter(tempDataLog,t('print-success'))
      
              } else {
                if(index > -1) {
                  requestIdPrint[index].status = 'failed'
                }
                let textErr = undefined
                if(response.code == 'EPTR_REC_EMPTY'){
                  textErr = returnCodePrinterToText(response.code)
                  // let publicKey = requestIdPrint[index].publicKey
                  // let indexPrint = requestIdPrint.findIndex((i: any,ind: number) => i.publicKey == publicKey && ind != index)
                  // if(indexPrint > -1 ) {
                  //   let a = requestIdPrint[indexPrint]
                  //   if(a.status == 'pending') {
                  //     requestIdPrint[indexPrint].status = 'failed'
                  //     reFuncPrintFailed(a.dataPrint,textErr)
                  //   }
                  // }
                }
                reFuncPrintFailed(dataPrint,textErr)
                try {
                  let tempData = {
                    funcName: `error print #${dataPrint.code} type: ${dataPrint.type} - ${dataPrint.data?.name} - response: ${response.code} - ${returnCodePrinterToText(response.code)} - ${response.status} `,
                    staffcode: dataPrint.dataInfo?.staffInfo?.code,
                    time: new Date(),
                  }
                  ApiService.post(`smarthub/logs/write`, tempData)
                } catch (error) {
                  
                }
                setLogPrinter(tempDataLog,`${t('print-error')} (${returnCodePrinterToText(response.code)})`,true)
                popupMessage({title: t('print-error') ,icon: 'info', description: `${returnCodePrinterToText(response.code)}`})
              }
              if(requestIdPrint.length != 0) {
                localforage.setItem('requestIdPrint',requestIdPrint)
              }
            };
          }
          if (retcode === "OK") {
            printer = devobj;
            funcReturnTempplatePrint(dataPrint,type,printer,ePosDev)
            reFuncPrintSuccess(dataPrint)
          } else {
            setLogPrinter(tempDataLog,`${t('print-error')} - (${retcode})`,true)
            reFuncPrintFailed(dataPrint)
          }
        }
      );
      
    } else {
      setLogPrinter(tempDataLog,`Send error (DEVICE_NOT_FOUND)`,true)
      reFuncPrintFailed(dataPrint)
      // console.log('data',data)
      throw data;
    }
  });
  return printer
};
const reFuncPrintFailed = async(dataPrint: any,moreinfo?: any) => {
  let type = dataPrint.dataInfo?.type
  if(dataPrint.type != 2 || type == 'voided' || type == 'transfer' || type == 'change') {
    Swal.close()
    return
  }
  console.log('data reFuncPrintFailed',dataPrint)
  let transactionlineids = dataPrint.HospTransactionLines?.filter((i: any) => i.id).map((i: any) => i.id)||[]
  if(transactionlineids.length != 0) {
    let urlApi = `smarthub/kotlines/updatemulti2`
    let tempData = {
      transactionlineids : transactionlineids, 
      data: {
        islineprinted: false,
        moreinfo: moreinfo?moreinfo: undefined,
      },
      staffcode: dataPrint.dataInfo?.staffInfo?.code,
      transactionid: dataPrint.id,
      tranlinedata: {
        isprinterror: true
      }

    }
    try {
      await ApiService.put(urlApi, tempData)
    } catch (error) {
      funcAddRequest({
        api: urlApi,
        method: 'put',
        body: tempData,
      })
      console.log('error',error)
    }
    Swal.close()
  }
}
export const funcAddRequest = async(data: any) => {
  let requestApi = await localforage.getItem('requestApi')||[] as any
  data.index = requestApi.length
  localforage.setItem('requestApi', [data].concat(requestApi))
}
export const funcAddRequestIdPrint = async(data: any) => {
  let requestIdPrint = await localforage.getItem('requestIdPrint')||[] as any
  data.index = requestIdPrint.length
  if(requestIdPrint.length != 0 && requestIdPrint.find((i: any) => i.id == data.id)) {
    return
  }
  if(requestIdPrint.length > 100) {
    requestIdPrint = requestIdPrint.filter((i: any) => i.index > 50).map((i: any,index: number) =>({...i, index: index}))
  }
  localforage.setItem('requestIdPrint', [data].concat(requestIdPrint))
} 
const reFuncPrintSuccess = (dataPrint: any) => {
  let type = dataPrint.dataInfo?.type
  if(dataPrint.type != 2 || type == 'voided' || type == 'transfer' || type == 'change') {
    Swal.close()
    return
  }
  // console.log('data reFuncPrintFailed',dataPrint)
  let transactionlineids = dataPrint.HospTransactionLines?.filter((i: any) => i.kitchentstatus != KITCHENSTATUS.voided && i.id && i.kotline && !i.kotline?.islineprinted).map((i: any) => i.id)||[]
  if(transactionlineids.length != 0) {
    let urlApi = `smarthub/kotlines/updatemulti2`
    try {
      let tempData = {
        transactionlineids : transactionlineids, 
        data: {
          islineprinted: true,
          moreinfo: null,
        },
        staffcode: dataPrint.dataInfo?.staffInfo?.code,
        transactionid: dataPrint.id,
        tranlinedata: {
          isprinterror: false,
        }
      }
      ApiService.put(urlApi, tempData)
    } catch (error) {
      console.log('error',error)
    }
  }
  Swal.close()
}
export function disconnect(printer: any,ePosDev: any) {
  //Discards the Printer object
  ePosDev.deleteDevice(printer, ePosDev.disconnect());
}


export const findAndPrint = async(itemLines: any,dataModal: any,dataStore: any, preifix?: any) => {
  let dataRender = [] as any 
  if(dataModal.typePrint == 1) {
    dataRender = [{
      items: unique_arr(itemLines,'lineno').filter((item: any) => item.kitchenstatus != KITCHENSTATUS.voided),
      name: 'RECEIPT'
    }]
  }
  else {
    dataRender = renderItemProduction(unique_arr(itemLines,'lineno'))
  }
  console.log('dataRender',dataRender)
  // console.log('itemLines',itemLines)
  formatDataPrint(dataRender,dataModal,dataStore,preifix)
}
export const prePrint = async(itemLines: any,dataModal: any,dataStore: any, preifix?: any) => {
  let dataRender = [{
    items: unique_arr(itemLines,'lineno').filter((item: any) => item.kitchenstatus != KITCHENSTATUS.voided),
    name: 'PREPRINT'
  }]
  formatDataPrint(dataRender,dataModal,dataStore,preifix)
}
export const printReceipt = async(itemLines: any,dataModal: any,dataStore: any, preifix?: any) => {
  let dataRender = [{
    items: unique_arr(itemLines,'lineno').filter((item: any) => item.kitchenstatus != KITCHENSTATUS.voided),
    name: 'RECEIPT'
  }]
  formatDataPrint(dataRender,dataModal,dataStore,preifix)
}
async function generateUniqueString(ids: any) {
  // Bước 1: Sắp xếp để đảm bảo thứ tự luôn giống nhau
  const sortedIds = ids.sort();
  
  // Bước 2: Ghép thành một chuỗi duy nhất
  const combinedString = sortedIds.join("-");

  // Bước 3: Tạo hàm băm (hash) SHA-256
  const encoder = new TextEncoder();
  const data = encoder.encode(combinedString);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  
  // Bước 4: Chuyển hash thành chuỗi dạng hex
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join("");

  // Bước 5: Cắt đúng 30 ký tự
  // console.log('hashHex',hashHex)
  return hashHex.slice(0, 20);
}
const formatDataPrint = async(dataRender: any,dataModal: any,dataStore: any, preifix?: any) => {
  let {staffInfo, setPrinterlist, PrinterList} = dataStore
  let merchantsData = await localforage.getItem('merchantsData')||{} as any
  let {productionsections, sectiondispstatmappings} = merchantsData
  let sectionid =  dataModal.sectionid
  // console.log('dataModal',dataModal)
  console.log('dataRender',dataRender)
  dataRender = unique_arr(dataRender,'name')
  let typePrint = dataModal.typePrint? dataModal.typePrint :  2 // 1: print order, 2: print ['BAR','KITCHEN'], 3: print-receipt, 4: preprint for kitchen ['BAR','KITCHEN']
  let publicKey = dataModal.code+'_'+new Date().getTime()
  dataRender.forEach(async(i: any) => {
    i.items = i.items.map((item: any) => {
        let tempPreifix = preifix
        // console.log('item',item)
        // if(item.kitchenstatus == KITCHENSTATUS.voided){
        //     // console.log('hủy mons',item)
        //     tempPreifix = 'HỦY MÓN'
        // }
        return {...item,prefix: tempPreifix?tempPreifix:''}
    })
    console.log('productionsections',productionsections)
   let code = productionsections?.find((p: any) => p.type == i.name)?.code
   console.log('code',code)

   if(code) {
    let printInf = sectiondispstatmappings?.find((s: any) => s.hospdiningareasectionid == sectionid && s.productionsectioncode == code)?.PrinterList
    // console.log('printInf',printInf)
    let store = {}
    if(dataModal.user) {
      store = dataModal.user||{}
    }
    let printjobid = await generateUniqueString(sortByNumber(i.items,'lineno').map((i: any,ind: number) => {
      let id = i.id
      if(!id) {
        id = `${dataModal.code}${i.lineno}${ind}`
      }
      return id
    }))
    console.log('printjobid',printjobid)
    console.log('publicKey',publicKey)
    if(printInf) {
        let printData = {
            data: {
                ...dataModal,
                printjobid: printjobid,
                publicKey: publicKey,
                dataInfo: {
                  ...dataModal.dataInfo,
                  staffInfo: staffInfo,
                },
                store: store,
                HospTransactionLines: sortByNumber(i.items,'lineno'),
                name: code == 'PREPRINT' ? 'Kiểm Món' : code,
                productionsectioncode: code,
                nameonreceipt: staffInfo.nameonreceipt,
                type: typePrint,
            },
            printConfig: {
                host: printInf.host,
                port: printInf.port
            },
            type: typePrint,
            setPrinterlist: setPrinterlist,
            PrinterList: PrinterList,
            
        }
        console.log('printData',printData)
        let findPrint = PrinterList?.find((i: any) => i.productionsectioncode == code)
        if(findPrint && false) {
          console.log('findPrint',findPrint)
        //   findPrint.printer?.status((statusObj: any) => {
        //     if (statusObj.connection) {
        //         console.log('Máy in đang kết nối và hoạt động bình thường');
        //     } else {
        //       console.log('Mất kết nối với máy in');
        //     }
        // });
          funcReturnTempplatePrint(printData.data,typePrint,findPrint.printer,findPrint.ePosDev)
        }
        else {
          connectPrintEpson(printData,setPrinterlist)
          try {
            let tempData = {
              funcName: `send print #${dataModal.code} type: ${typePrint} - ${printData.data?.name}`,
              staffcode: staffInfo?.code,
              time: new Date(),
            }
            ApiService.post(`smarthub/logs/write`, tempData)
          } catch (error) {
            
          }
        }
    }
    else {
        popupMessage({description:"Please check config printer!!", icon: 'info'})
    }
   }
   else {
    popupMessage({description:"Please check setup productionsections item!!", icon: 'info'})
}
})
}
