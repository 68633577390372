import { FC } from "react"
import { ShowForPermission } from "../../../../app/modules/auth/Permission"
import { KTSVG } from "../utils/KTSVG"
import { useNavigate } from "react-router-dom"
import { ApiShowError } from "../../../helpers"
import { popupDelete, popupQuestion } from "../../../../app/modules/messages"

interface IRowIcon {
    permission?: string
    to?: string
    onClick?: () => void
    action: 'delete' | "insert" | "modify" | "execute" | "read" | "approved" | "denied" | "print" | "download" | "reset"
    question?: string
    className?: string
    disabled?: boolean
}

const contents = {
    'download': {
        color: 'success',
        icon: 'bi bi-download',
        question: "message.question.download"
    },
    'print': {
        color: 'info',
        icon: 'bi bi-printer'
    },
    'reset': {
        color: 'warning',
        icon: 'bi bi-arrow-repeat',
    },
    'delete': {
        color: 'danger',
        icon: '/media/icons/duotune/general/gen027.svg'
    },
    'modify': {
        color: 'primary',
        // icon: '/media/icons/duotune/art/art005.svg'
        icon: '/media/icons/custom/svgexport.svg'
    },
    'execute': {
        color: 'warning',
        icon: '/media/icons/duotune/general/gen027.svg'
    },
    'insert': {
        color: 'primary',
        icon: '/media/icons/duotune/general/gen027.svg'
    },
    'read': {
        color: 'primary',
        icon: '/media/icons/duotune/general/gen027.svg'
    },
    'approved': {
        color: 'success',
        icon: '/media/icons/duotune/arrows/arr084.svg',
        title: 'Phê duyệt',
        class: 'svg-icon-2'
    },
    'denied': {
        color: 'danger',
        icon: '/media/icons/custom/denied.svg',
        title: "Từ chối",
        class: 'svg-icon-2'
    },
} as any

export const RowIcon: FC<IRowIcon> = ({ disabled, permission, to, onClick, action, question, className }) => {
    const navigate = useNavigate()

    const content = <button
        disabled={disabled}
        type="button"
        title={contents[action].title}
        className={`btn btn-sm btn-icon btn-light-${contents[action]?.color} ${className ? className : ''}`}
        onClick={to ? function () { navigate(to) } : (
            async () => {
                try {
                    let isQuestion = true
                    if (action == 'delete') isQuestion = await popupDelete()
                    if (typeof question == 'string') isQuestion = await popupQuestion({ description: question })
                    if (isQuestion && onClick) onClick()
                } catch (error) {
                    ApiShowError(error)
                }
            }
        )}>
        {!contents[action].icon.includes('bi-')
            ? <KTSVG
                path={contents[action].icon}
                className={`${contents[action].class ? contents[action].class : 'svg-icon-4'} svg-icon-${contents[action].color}`} />
            : <i className={`fs-3 ${contents[action].icon}`}></i>
        }

    </button>

    return permission
        ? <ShowForPermission permission={[permission, action.toUpperCase()]}>
            {content}
        </ShowForPermission>
        : content
}