import clsx from "clsx"
import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { ShowForPermission } from "../../../../app/modules/auth/Permission"
import { ApiShowError } from "../../../helpers"
import { KTSVG } from "../utils/KTSVG"
import { popupQuestion } from "../../../../app/modules/messages"
import { useTranslation } from "react-i18next"

interface IKTButton {
    permission?: string
    to?: string
    onClick?: () => void
    action: 'new' | 'inew' | 'sync' | 'back' | 'upload' | 'filter' | 'refesh' | 'generate' | 'delete'
    question?: string
    titleBtn?: string
    disabled?: boolean
    hiddenTitle?: boolean
}

export const KTButton: FC<IKTButton> = ({ disabled, permission, to, onClick, action, question, titleBtn,hiddenTitle }) => {
    const { t } = useTranslation()
    let contents = {
        'delete': {
            text: 'Delete',
            color: 'danger',
            icon: 'fa-solid fa-trash',
        },
        'generate': {
            text: 'Generate',
            color: 'primary',
            icon: 'fa-solid fa-rotate',
        },
        'new': {
            text: 'Add',
            color: 'light-primary',
            icon: 'fa-solid fa-plus',
            className: 'border-primary'
        },
        'inew': {
            text: 'Add',
            color: 'light-primary',
            icon: 'fa-solid fa-plus',
            className: 'border-primary'
        },
        'sync': {
            text: 'Sync',
            color: 'light-primary',
            icon: 'fa-solid fa-rotate',
            className: 'border-primary'

        },
        'back': {
            text: 'Back',
            color: 'light-gray',
            icon: 'bi bi-arrow-left',
            className: 'border-gray'
        },
        'upload': {
            text: 'Upload',
            color: 'light-green',
            icon: '/media/icons/custom/export.svg',
            className: 'border border-2 border-green btn-icon-custom',
            svgClass: 'svg-custom svg-stroke svg-icon-2x me-0',
            hiddenTitle: true,
            isSvg: true,
        },
        'filter': {
            text: 'Filter',
            color: 'light-orange',
            icon: '/media/icons/custom/filter.svg',
            className: 'border border-2 border-orange btn-icon-custom',
            svgClass: 'svg-custom svg-stroke me-0',
            hiddenTitle: true,
            isSvg: true,
        },
        'refesh': {
            text: 'Refresh',
            color: 'light-info',
            icon: '/media/icons/custom/refesh.svg',
            className: 'border border-2 border-info btn-custom',
            svgClass: 'svg-custom svg-stroke me-0',
            isSvg: true,
        },
    } as any
    if (titleBtn) {
        contents[action].text = titleBtn
    }
    const navigate = useNavigate()
    const { icon, color, text, className, svgClass } = contents[action]
    const isSvg = icon && (icon.split('media/icons/').length > 1)

    const content = <div
        className={clsx(`btn btn-sm btn-${color} ${className} btn-custom border border-2`, {
            'disabled': disabled,
        })}
        onClick={to ? function () { navigate(to) } : (
            async () => {
                try {
                    let isQuestion = true
                    if (action == 'new' && !onClick) return navigate('new')
                    if (typeof question == 'string') isQuestion = await popupQuestion({ description: question })
                    if (isQuestion && onClick) onClick()
                } catch (error) {
                    ApiShowError(error)
                }
            }
        )}>
        {icon && <span className={clsx('menu-icon',{'me-1': !hiddenTitle})}>
            {isSvg
                ? <KTSVG path={icon} className={clsx('svg-icon-2',svgClass)} />
                : <i className={clsx('', icon)}></i>
            }
        </span>}
        {!hiddenTitle && text}
    </div>

    return permission ? <ShowForPermission permission={[permission, action.toUpperCase()]}>
        {content}
    </ShowForPermission> : content
}