import { useForm } from "react-hook-form";
import { KTFormItem } from "../../../theme/partials";
import { ITransaction } from "./new";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { ApiService, formatCurrent, sortWithAny } from "../../../theme/helpers";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const colors: { [key: string]: string } = {
    "Open": "warning",
    "Active": "primary",
    "Ready": "info",
    "Completed": "success",
    "Cancel": "danger",
}

export default function TransactionDetail() {
    const { id } = useParams()
    const urlString = `smarthub/hosptransactions/${id}?expand=hosptransactionlines,hospitem,TransactionPaymentEntries,KOTLine`
    const { data: transactionData, isFetching } = useQuery([urlString], () => ApiService(urlString).then(res => res.data), { enabled: !!id })
    const { register, watch, reset } = useForm<ITransaction>()
    const [completedAt, setCompletedAt] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (!isFetching && transactionData) {
            reset({
                ...transactionData,
                grossamount: formatCurrent(transactionData.grossamount),
                netamount: formatCurrent(transactionData.netamount),
                taxamount: formatCurrent(transactionData.taxamount),
                discountamount: formatCurrent(transactionData.discountamount),
                HospTransactionLines: sortWithAny(transactionData.HospTransactionLines, { sort: 'lineno' }),
            })
            if (transactionData.TransactionPaymentEntries?.length > 0) {
                if (transactionData.TransactionPaymentEntries[0].paymentdate && transactionData.TransactionPaymentEntries[0].paymenttime) {
                    const date = new Date(transactionData.TransactionPaymentEntries[0].paymentdate)
                    const time = transactionData.TransactionPaymentEntries[0].paymenttime.split(':')
                    const completedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), Number(time[0]), Number(time[1]), Number(time[2]))
                    setCompletedAt(completedDate.toLocaleString('en-GB'))
                }
            }
        }
    }, [isFetching])

    const [modalKot, setModalKot] = useState(false)
    const HospTransactionLines = watch('HospTransactionLines') || []
    const TransactionPaymentEntries = watch('TransactionPaymentEntries') || []

    return <>
        <div className="my-6  d-flex align-items-center justify-content-between">
            <div className="fs-4 fw-bold">#{watch('code')}</div>
            <button className="btn btn-sm border border-gray-400 bg-white ps-4" onClick={() => window.history.back()}>
                <div className="d-flex align-items-center gap-1">
                    <i className="bi bi-chevron-left text-dark"></i><span className="lh-1"> Back</span>
                </div>
            </button>
        </div>
        <div className="card card-body">
            <div className="d-flex algin-items-center">
                <div className="fs-4 fw-bold">Thông tin chung</div>
                <div className="ms-auto d-flex align-items-center gap-2">
                    <button className="btn btn-sm btn-icon btn-light-info">
                        <i className="bi bi-three-dots-vertical"></i>
                    </button>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-6">
                    <KTFormItem title="Mã giao dịch">
                        <input disabled className="form-control form-control-sm" {...register('code')} />
                    </KTFormItem>
                    <KTFormItem title="Ngày tạo">
                        <input disabled className="form-control form-control-sm" {...register('transactiondate')} />
                    </KTFormItem>
                    <KTFormItem title="Thời gian">
                        <input disabled className="form-control form-control-sm" {...register('transactiontime')} />
                    </KTFormItem>
                    <KTFormItem title="Hoàn tất lúc">
                        <input disabled className="form-control form-control-sm" value={completedAt} onChange={() => { }} />
                    </KTFormItem>
                    <KTFormItem title="Mã receipt">
                        <input disabled className="form-control form-control-sm" {...register('receiptno')} />
                    </KTFormItem>
                    <KTFormItem title="Sale type">
                        <input disabled className="form-control form-control-sm" {...register('saletype')} />
                    </KTFormItem>
                    <KTFormItem title="Người tạo">
                        <input disabled className="form-control form-control-sm" {...register('staffcode')} />
                    </KTFormItem>
                </div>
                <div className="col-6">
                    <KTFormItem title="Khu vực">
                        <input disabled className="form-control form-control-sm" {...register('sectionname')} />
                    </KTFormItem>
                    <KTFormItem title="Bàn số">
                        <input disabled className="form-control form-control-sm" {...register('tablename')} />
                    </KTFormItem>
                    <KTFormItem title="Tổng cộng">
                        <input disabled className="form-control form-control-sm" {...register('grossamount')} />
                    </KTFormItem>
                    <KTFormItem title="Tiền trước thuế">
                        <input disabled className="form-control form-control-sm" {...register('netamount')} />
                    </KTFormItem>
                    <KTFormItem title="Tiền thuế">
                        <input disabled className="form-control form-control-sm" {...register('taxamount')} />
                    </KTFormItem>
                    <KTFormItem title="Giảm giá">
                        <input disabled className="form-control form-control-sm" {...register('discountamount')} />
                    </KTFormItem>
                    <KTFormItem title="Trạng thái">
                        <div className={`badge badge-${colors[watch('transactionstatus')] || 'secondary'}`}>
                            {watch('transactionstatus')}
                        </div>
                    </KTFormItem>
                </div>
                <div className="col-12">
                    <hr />
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <div className="fs-4 fw-bold">Thông tin chi tiết</div>
                        <button type="button" className="btn btn-sm border border-dark text-dark" onClick={() => setModalKot(true)}>KOT</button>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-row-bordered gs-2 gx-4 gy-2 bg-light text-nowrap m-0">
                            <thead>
                                <tr>
                                    <th className="text-start">Line No.</th>
                                    <th className="text-start">Item No.</th>
                                    <th className="text-start">IT Item Name</th>
                                    <th className="text-start">Unit of Measure</th>
                                    <th className="text-start">Date</th>
                                    <th className="text-start">Time</th>
                                    <th className="text-start">Quantity</th>
                                    <th className="text-start">Price</th>
                                    <th className="text-start">Net Amount</th>
                                    <th className="text-start">VAT Amount</th>
                                    <th className="text-start">Discount Amount</th>
                                    <th className="text-start">Staff ID</th>
                                    <th className="text-start">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {HospTransactionLines.map((item, index: number) => (
                                    <tr key={index}>
                                        <td>{item.lineno}</td>
                                        <td>{item.itemno}</td>
                                        <td>{item.itemname}</td>
                                        <td>{item.unitofmeasure}</td>
                                        <td>{item.transactiondate}</td>
                                        <td>{item.transactiontime}</td>
                                        <td>{item.quantity}</td>
                                        <td>{formatCurrent(item.price)}</td>
                                        <td>{formatCurrent(item.netamount)}</td>
                                        <td>{formatCurrent(item.vatamount)}</td>
                                        <td>{formatCurrent(item.discountamount)}</td>
                                        <td>{item.staffcode}</td>
                                        <td>{item.kitchenstatus}</td>
                                    </tr>
                                ))}
                                {HospTransactionLines.length === 0 && (
                                    <tr className="text-center">
                                        <td colSpan={13}>Không có dữ liệu</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-12">
                    <hr />
                    <div className="fs-4 fw-bold mb-4">Thanh toán</div>
                    <div className="table-responsive">
                        <table className="table table-striped table-row-bordered gs-2 gx-4 gy-2 bg-light text-nowrap m-0">
                            <thead>
                                <tr>
                                    <th className="text-start">Tender Type</th>
                                    <th className="text-start">Tender Name</th>
                                    <th className="text-start">Change Line</th>
                                    <th className="text-start">Amount Tendered</th>
                                    <th className="text-start">Date</th>
                                    <th className="text-start">Time</th>
                                    <th className="text-start">Staff ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {TransactionPaymentEntries.map((item, index: number) => (
                                    <tr key={index}>
                                        <td>{item.tendertype}</td>
                                        <td>{item.tendertypename}</td>
                                        <td>
                                            <div className="form-check form-check-sm form-check-custom">
                                                <input className="form-check-input" type="checkbox" checked={item.ischangeline} onChange={() => { }} />
                                            </div>
                                        </td>
                                        <td>{formatCurrent(item.amounttendered)}</td>
                                        <td>{item.paymentdate}</td>
                                        <td>{item.paymenttime}</td>
                                        <td>{item.staffcode}</td>
                                    </tr>
                                ))}
                                {TransactionPaymentEntries.length === 0 && (
                                    <tr className="text-center">
                                        <td colSpan={7}>Không có dữ liệu</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Modal show={modalKot} onHide={() => setModalKot(false)} size="xl" centered>
            <Modal.Header closeButton className="py-3">
                <Modal.Title>KOT Lines</Modal.Title>
            </Modal.Header>
            <Modal.Body className="table-responsive">
                <table className="table table-striped table-row-bordered gs-2 gx-4 gy-2 bg-light text-nowrap m-0">
                    <thead>
                        <tr>
                            <th className="text-start">Line No.</th>
                            <th className="text-start">Item No.</th>
                            <th className="text-start">IT Item Name</th>
                            <th className="text-start">Qty</th>
                            <th className="text-start">Uom</th>
                            <th className="text-start">Category</th>
                            <th className="text-start">Sub Category</th>
                            <th className="text-start">Is Line Printed</th>
                            <th className="text-start">Is Voided</th>
                            <th className="text-start">Datetime Voided</th>
                            <th className="text-start">Fired Datetime</th>
                            <th className="text-start">Served Datetime</th>
                            <th className="text-start">Message</th>
                            <th className="text-start">More Info</th>
                            <th className="text-start">Modifier</th>
                            <th className="text-start">Creater</th>
                            <th className="text-start">Modified At</th>
                            <th className="text-start">Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {HospTransactionLines.filter(f => !!f.lineno).map(({ kotline }, index: number) => (
                            <tr key={index}>
                                <td>{kotline?.lineno}</td>
                                <td>{kotline?.itemno}</td>
                                <td>{kotline?.itemdescription}</td>
                                <td>{kotline?.quantity}</td>
                                <td>{kotline?.uom}</td>
                                <td>{kotline?.category}</td>
                                <td>{kotline?.subcategory}</td>
                                <td>
                                    <div className="form-check form-check-sm form-check-custom">
                                        <input className="form-check-input" type="checkbox" checked={kotline?.islineprinted} onChange={() => { }} />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-check form-check-sm form-check-custom">
                                        <input className="form-check-input" type="checkbox" checked={kotline?.isvoided} onChange={() => { }} />
                                    </div>
                                </td>
                                <td>{kotline?.datetimevoided ? new Date(kotline?.datetimevoided).toLocaleString('en-GB') : ''}</td>
                                <td>{kotline?.fireddatetime ? new Date(kotline?.fireddatetime).toLocaleString('en-GB') : ''}</td>
                                <td>{kotline?.serveddatetime ? new Date(kotline?.serveddatetime).toLocaleString('en-GB') : ''}</td>
                                <td>{kotline?.message}</td>
                                <td>{kotline?.moreinfo}</td>
                                <td>{kotline?.modifier}</td>
                                <td>{kotline?.creater}</td>
                                <td>{kotline?.modifiedat ? new Date(kotline?.modifiedat).toLocaleString('en-GB') : ''}</td>
                                <td>{kotline?.createdat ? new Date(kotline?.createdat).toLocaleString('en-GB') : ''}</td>
                            </tr>
                        ))}
                        {HospTransactionLines.length === 0 && (
                            <tr className="text-center">
                                <td colSpan={13}>Không có dữ liệu</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    </>
}