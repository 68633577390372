import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { checkIsActive } from '../../../../helpers'
import { KTSVG } from '../../../../partials/widgets/utils/KTSVG'

type Props = {
  to: string
  title: string
  icon?: string
  hasBullet?: boolean
  defaultOpen?: boolean
  isLink?: boolean
  children?: ReactNode
}

const SidebarMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  hasBullet,
  defaultOpen,
  isLink,
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const isOpen = checkIsActive(pathname, to) || defaultOpen

  const isSvg = icon && (icon?.split('media/icons/').length > 1)
  // const templateMenu = () => {
  //   return <>
  //   {hasBullet && (
  //         <span className='menu-bullet'>
  //           <span className='bullet bullet-dot'></span>
  //         </span>
  //       )}
  //       {icon && <span className='menu-icon'>
  //         {isSvg
  //           ? <KTSVG path={icon} className='svg-icon-2' />
  //           : <i className={clsx('fs-3', icon)}></i>
  //         }
  //       </span>}
  //       <span className='menu-title'>{title}</span>
  //       <span className='menu-arrow'></span>
  //   </>
  // }
  // return (
  //   isLink ?
  //   <div
  //     className={clsx('menu-item', { 'here show': isOpen }, 'menu-accordion')}
  //   >
  //     <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
  //       {templateMenu()}
  //     </Link>
  //     <div className={clsx('menu-sub menu-sub-accordion', { 'menu-active-bg': isActive })}>
  //       {children}
  //     </div>
  //   </div>
  //   :
  //   <div
  //     className={clsx('menu-item', { 'here show': isOpen }, 'menu-accordion')}
  //     data-kt-menu-trigger='click'
  //   >
  //     <span className='menu-link'>
  //       {templateMenu()}
  //     </span>
  //     <div className={clsx('menu-sub menu-sub-accordion', { 'menu-active-bg': isActive })}>
  //       {children}
  //     </div>
  //   </div>
  // )

  return (
    <div
      className={clsx('menu-item', { 'here show': isActive }, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && <span className='menu-icon'>
          {isSvg
            ? <KTSVG path={icon} className='svg-icon-2' />
            : <i className={clsx('fs-3', icon)}></i>
          }
        </span>}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', { 'menu-active-bg': isActive })}>
        {children}
      </div>
    </div>
  )
}

export { SidebarMenuItemWithSub }

