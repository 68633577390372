import clsx from "clsx"
import 'flatpickr/dist/flatpickr.css'
import { FC, lazy } from 'react'

const Flatpickr = lazy(() => import('react-flatpickr'))

interface IInputTime {
    id?: string
    value: string
    onChange: (time: string) => void
    className?: string
    disabled?: boolean
    onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined
    onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined
}

export const InputTime: FC<IInputTime> = (props) => {

    return <Flatpickr
        value={props.value}
        onChange={(e) => {
            props.onChange(e[0].toLocaleString('en-GB').split(', ')[1])
        }}
        id={props.id}
        disabled={props.disabled}
        options={{
            allowInvalidPreload: false,
            defaultHour: 6,
            defaultMinute: 0,
            minuteIncrement: 1,
            clickOpens: false,
            static: true,
            allowInput: true,
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
        }}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        className={clsx('form-control', props.className)} />
}