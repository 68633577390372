import clsx from 'clsx'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatCurrent } from '../../../../helpers'

interface Props {
  data: string | number | boolean | undefined
  className?: string
  type?: 'date' | 'datetime' | 'time' | 'currency' | 'img'
  badge?: string
  badgeBorder?: boolean
  to?: string
  onClick?: () => void
}

export const BasicCell: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  let temp: any = props.data && props.data != 'NaN' && props.data != 'Invalid Date' ? props.data : props.data === 0 ? '0' : ''
  // format date
  if (props.type == 'date' && temp != '') {
    temp = new Date(temp).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })
  } else if (props.type == 'datetime' && temp != '') {
    temp = new Date(temp).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
  } else if (props.type == 'time' && temp != '') {
    temp = new Date(temp).toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit' })
  }
  else if (props.type == 'currency' ) { 
    temp = formatCurrent(temp)
  }
  // render boolean
  if (typeof props.data == 'boolean') {
    const isAcive = props.data
    temp = <div className="form-check form-check-custom form-check-solid form-check-sm">
      <input className="form-check-input w-15px h-15px" type="checkbox" value="" onChange={() => { }} id="flexCheckDefault" checked={isAcive} />
    </div>
  }
  if (props.type == 'img' && !!props.data) {
    temp = <img src={props.data as any} className='img-auto' style={{ width: '40px', height: '40px', objectFit: "contain" }} />
  }

  const isClick = typeof props.to == 'string'
    || typeof props.onClick == 'function'

  return <div
    className={clsx(props.className, {
      'cursor-pointer': isClick,
      'text-primary': isClick
    })}
    onClick={() => {
      if (props.to) return navigate(props.to)
      props.onClick && props.onClick()
    }}>
    {props.badge ? <BasicCellBadge color={props.badge} outline={props.badgeBorder} temp={temp} /> : temp}
  </div>
}

interface IBasicCellBadge {
  color: string,
  outline?: boolean,
  temp: any
}

const BasicCellBadge: FC<IBasicCellBadge> = ({ color, outline, temp }) => <span className={clsx(
  color && `m-auto badge badge-${color}`,
  outline && `border border-${color}`
)}>
  {temp}
</span>