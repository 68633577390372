import { yupResolver } from "@hookform/resolvers/yup"
import clsx from "clsx"
import { Reorder } from "framer-motion"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { Column } from "react-table"
import * as yup from 'yup'
import { ApiService, ApiShowError, sortByNumber } from "../../../theme/helpers"
import { BasicCell, CustomHeader, FormSubmit, InputSelect, KTButton, KTFormItem, KTTable, RowIcon, SearchTable } from "../../../theme/partials"
import { KTSVG } from "../../../theme/partials/widgets/utils/KTSVG"
import { IInitField } from "../../modules/api"
import { getAuth } from "../../modules/auth"
import { popupDelete, popupMessage } from "../../modules/messages"
import WeeklyTimingCard from "./orderSettings"
import QrCodeCard from "./qrCode"

const tabs = [
    { path: 'section-and-table', title: 'Section And Table', ico: '/media/icons/menu/dinner-table.svg', width: 150 },
    { path: 'qr-code', title: 'QR Code', ico: '/media/icons/menu/qr-code.svg', width: 100 },
    { path: 'weekly-timing', title: 'Weekly Timing', ico: '/media/icons/menu/weekly_timing.svg', width: 150 },
]

export default function ConfigurationTable() {
    const navigate = useNavigate()
    const { code } = useParams()
    const { t } = useTranslation()

    const [merchant, setMerchant] = useState<any>(null)
    const [storeTier, setStoreTier] = useState<any>(null)

    async function loadStoreTier(storetierid: string) {
        const _storeTier = await ApiService(`/settings/storetiers/${storetierid}`)
        setStoreTier(_storeTier.data)
    }

    useEffect(() => {
        const getAuthData = async () => {
            const authData: any = (await getAuth())?.user || {}
            const _merchant = await ApiService(`/fnb/merchants/${authData.store.merchantid}`)
            setMerchant(_merchant.data)
            loadStoreTier(_merchant.data.storetierid)
        }
        getAuthData()
    }, [])

    if (!merchant || !storeTier) return null;

    return <div className="card mt-12">
        <SearchTable data={[]} setTableData={() => { }} className='search-fix-header' keySearch={[]} />
        <div className="card-header bg-secondary rounded-top">
            <h3 className="card-title">
                {tabs.map(item => {
                    const isActive = code == item.path
                    const text = t(item.path)

                    return <div
                        key={item.title}
                        onClick={() => navigate(`/configuration/${item.path}`)}
                        className={clsx('btn p-3 py-1 rounded min-w-125px text-center', {
                            'bg-orange text-white': isActive,
                        })}>
                        <KTSVG
                            path={item.ico}
                            className={clsx(`svg-icon-2 d-block m-0`, isActive ? 'svg-icon-white' : 'svg-icon-dark')}
                        />
                        {text}
                    </div>
                })}
            </h3>
        </div>
        <div className="card-body">
            {code == 'section-and-table'
                && <SectionAndTableCard merchantid={merchant.id} />}
            {code == 'qr-code'
                && <QrCodeCard merchantid={merchant.id} enableordertype={merchant.enableordertype} ordertypes={storeTier.ordertypes} />}
            {code == 'weekly-timing'
                && <WeeklyTimingCard merchantid={merchant.id} storetierid={storeTier.id} ordertypes={storeTier.ordertypes} setStoreTier={setStoreTier} />}
        </div>
    </div>
}

export interface IHospDiningAreaSection extends IInitField {
    seq: number
    sectioncode: string | null
    sectionname: string | null
    salestype: string | null
    servicetype: string
    merchantid: string | null
}

export interface IHospDiningTable extends IInitField {
    merchantid: string | null
    sectionid: string | null
    sectioncode: string | null
    tableno: string | null
    tablename: string | null
    seatcapacity: number | null
    mincapacity: number | null
    maxcapacity: number | null
    qrcodeordertype: string | null
    qrcodetype: string | null
    expirationtime: number | null
    qrexpireat: string | null
    qrcodethumb: string | null
    qrcode: string | null
    active: boolean
    diningtablestatus?: string | null
    salestype?: string | null
}

export function changeDataModify(data: any) {
    delete data.createdat
    delete data.createdby
    delete data.modifiedat
    delete data.modifiedby
    delete data.modifier
    delete data.creater
    return data
}

function SectionAndTableCard({ merchantid }: any) {
    const { data: dataAllTables, isSuccess: isTables, refetch: refetchAllTables } = useQuery(
        [`fnb/hospdiningtables?merchantid=${merchantid}`],
        () => ApiService.get(`fnb/hospdiningtables?merchantid=${merchantid}`).then(res => res.data),
        { enabled: !!merchantid }
    )
    const { data: dataSections, isSuccess: isSections, isFetching: loadSections } = useQuery(
        [`fnb/hospdiningareasections?merchantid=${merchantid}`],
        () => ApiService.get(`fnb/hospdiningareasections?merchantid=${merchantid}`).then(res => res.data),
        { enabled: !!merchantid }
    )
    const [sections, setSections] = useState<IHospDiningAreaSection[]>([])
    const [checkedSection, setCheckedSection] = useState<IHospDiningAreaSection | undefined>()

    useEffect(() => {
        if (!loadSections && isSections) {
            const array = sortByNumber(dataSections, 'seq')
            setSections(array)
            setCheckedSection(Array.isArray(array) && array[0] ? array[0] : undefined)
        }
    }, [loadSections])

    const { watch, register, handleSubmit, reset, setValue, formState: { errors, isSubmitting } } = useForm<IHospDiningAreaSection>({
        resolver: yupResolver(yup.object().shape({
            sectionname: yup.string().required(),
            servicetype: yup.string().required(),
        }))
    })
    const [valueSection, setValueSection] = useState<IHospDiningAreaSection | undefined>()
    const isEdit = valueSection?.id != undefined
    const valueid = valueSection?.id || null

    const addSection = () => {
        const newSection = { seq: 0, merchantid: merchantid as any, sectioncode: null, sectionname: null, salestype: null, servicetype: 'Not Specified' }
        setValueSection(newSection)
        reset(newSection)
    }

    const onReorder = async (items: any[]) => {
        let _items = items.map((item, index: number) => ({ ...item, seq: index + 1 }))
        try {
            await ApiService.post('fnb/hospdiningareasections/upsertmulti', _items)
            setSections(_items)
        } catch (error) {
            ApiShowError(error)
        }
    }

    useEffect(() => {
        reset(valueSection)
    }, [valueSection])

    const serverTypeOptions = [
        { value: 'Not Specified', label: 'Not Specified' },
        { value: 'Dining Table Service', label: 'Dining Table Service' },
        { value: 'Self Service', label: 'Self Service' },
        { value: 'Delivery', label: 'Delivery' }
    ]

    return <>
        <div className="d-flex gap-6">
            <div className="w-275px">
                <div className="d-flex flex-stack">
                    <div className="fs-5 p-2">
                        Sections
                    </div>
                    <KTButton action="new" onClick={addSection} />
                </div>
                <hr className="mt-0" />
                <Reorder.Group as="div" axis="y" values={sections} onReorder={onReorder} className="d-flex flex-column gap-2">
                    {sections.map((item: any, index: number) => {
                        const isActive = item.id == checkedSection?.id

                        return <Reorder.Item as="div" key={item.id} value={item}>
                            <div
                                className={clsx(
                                    "ps-3 border border-secondary cursor-pointer",
                                    isActive ? "bg-primary text-white" : "bg-white text-dark"
                                )}
                                onClick={() => {
                                    setCheckedSection(item)
                                    reset(item)
                                }}>
                                <div className="d-flex align-items-center gap-2 min-h-35px">
                                    <span>{item.sectionname}</span>
                                    <div
                                        className="btn btn-icon btn-sm ms-auto"
                                        onClick={() => setValueSection(item)}
                                    >
                                        <KTSVG path={'/media/icons/custom/svgexport.svg'} className={clsx(`svg-icon-4`, isActive ? 'svg-icon-white' : 'svg-icon-primary')} />
                                    </div>
                                </div>
                            </div>
                        </Reorder.Item>
                    })}
                </Reorder.Group>
            </div>
            <div className="flex-fill">
                {isTables && <CardTablesBySection valueSection={checkedSection} dataAllTables={dataAllTables} refetchAllTables={refetchAllTables} />}
            </div>
        </div>
        <Modal show={valueSection != undefined} onHide={() => setValueSection(undefined)} centered>
            <Modal.Header closeButton>
                <Modal.Title>{valueSection?.id ? 'Edit' : 'Add'} section</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(async (data: any) => {
                    try {
                        const method = isEdit ? 'put' : 'post'
                        const result = await ApiService[method](
                            `/fnb/hospdiningareasections${isEdit ? `/${valueid}` : ''}`,
                            changeDataModify(data)
                        )
                        // if (valueSection?.salestype != data.salestype) {
                        //     const tables = await ApiService.get(`/fnb/hospdiningtables?sectionid=${valueSection?.id}&merchantid=${dataMerchant?.id}`)
                        //     const _tables = tables.data.map((t: any) => ({ ...t, salestype: data.salestype ?? null }))
                        //     await ApiService.post(`/fnb/hospdiningtables/upsertmulti`, _tables)
                        // }
                        popupMessage({ icon: 'success', autoClose: true })
                        setSections(isEdit ? sections.map(m => m.id == valueid ? data : m) : [result.data, ...sections])
                        if (!isEdit) setCheckedSection(result.data)
                        setValueSection(undefined)
                    } catch (error) {
                        ApiShowError(error)
                    }
                })}>
                    {/* <KTFormItem title="SEQ" isBLockLabel>
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.seq })} {...register('seq')} />
                    </KTFormItem> */}
                    <KTFormItem title="Section Name" isBLockLabel>
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.sectionname })} {...register('sectionname')} />
                    </KTFormItem>
                    <KTFormItem title="Sales Type" isBLockLabel>
                        <input className={clsx("form-control form-control-sm", { "form-error": errors.salestype })} {...register('salestype')} />
                    </KTFormItem>
                    <KTFormItem title="Service Type" isBLockLabel>
                        <InputSelect
                            className={clsx("w-100", { 'form-error': errors.servicetype })}
                            options={serverTypeOptions}
                            value={serverTypeOptions.find((f: any) => f.value == watch('servicetype')) ?? null}
                            onChange={option => {
                                setValue('servicetype', option?.value ?? null)
                            }}
                        />
                    </KTFormItem>
                    <div className="mt-6 d-flex flex-end gap-2">
                        {!!valueSection?.id && <KTButton
                            question="Do you want to delete this section?"
                            disabled={!valueSection?.id}
                            action='delete'
                            onClick={async () => {
                                setValueSection(undefined)
                                const question = await popupDelete()
                                const tables = dataAllTables.filter((f: any) => f.sectionid == valueSection?.id)
                                if (tables.length) {
                                    return popupMessage({
                                        icon: "error",
                                        description: 'This section has tables, please delete them first',
                                    })
                                }
                                if (question && valueSection?.id) {
                                    try {
                                        await ApiService.delete(`fnb/hospdiningareasections/${valueSection.id}`)
                                        setSections(sections.filter(f => f.id != valueSection.id))
                                        popupMessage({ icon: 'success', autoClose: true })
                                    } catch (error) {
                                        ApiShowError(error)
                                    }
                                }
                            }} />}
                        <FormSubmit
                            type={isEdit}
                            isSubmitting={isSubmitting}
                            handleClose={() => setValueSection(undefined)}
                        />
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    </>
}

interface ICardTablesBySection {
    valueSection?: IHospDiningAreaSection
    dataAllTables: IHospDiningTable[]
    refetchAllTables: any,
    permission?: string
}

function CardTablesBySection({ valueSection, dataAllTables, refetchAllTables, permission }: ICardTablesBySection) {
    const [tables, setTables] = useState<IHospDiningTable[]>([])
    const [valueTable, setValueTable] = useState<IHospDiningTable | undefined>()

    useEffect(() => {
        if (valueSection?.id) {
            setTables(sortByNumber(dataAllTables.filter(f => valueSection.id == f.sectionid), 'tableno'))
        } else {
            setTables([])
        }
    }, [valueSection?.id])

    const columnsTable: Array<Column<IHospDiningTable>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='Table No.' />,
        accessor: 'tableno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].tableno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Table Name' />,
        accessor: 'tablename',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].tablename} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Seat Capacity' />,
        accessor: 'seatcapacity',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].seatcapacity} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Sales Type' />,
        accessor: 'salestype',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].salestype} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Active' className="w-80px" />,
        accessor: 'active',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].active} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Actions' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" onClick={() => setValueTable(props.data[props.row.index])} />
            <RowIcon action="delete" onClick={async () => {
                const id = props.data[props.row.index].id
                await ApiService.delete(`fnb/hospdiningtables/${id}`)
                popupMessage({ icon: 'success', autoClose: true })
                setTables(pre => pre.filter(f => f.id != id))
                refetchAllTables()
            }} />
        </div>
    }]

    return <>
        <div className="d-flex flex-stack">
            <span className="fs-5 p-2">Dining Tables</span>
            <KTButton
                permission={permission}
                action="new"
                disabled={!valueSection}
                onClick={() => {
                    if (valueSection) {
                        const { merchantid, id, sectioncode } = valueSection
                        merchantid && setValueTable({
                            merchantid: merchantid,
                            sectionid: id || null,
                            sectioncode: sectioncode,
                            tableno: null,
                            tablename: null,
                            seatcapacity: null,
                            mincapacity: null,
                            maxcapacity: null,
                            qrcodeordertype: null,
                            qrcodetype: null,
                            expirationtime: null,
                            qrexpireat: null,
                            qrcodethumb: null,
                            qrcode: null,
                            active: false,
                            diningtablestatus: 'free'
                        })
                    }
                }}
            />
        </div>
        <hr className="mt-0" />
        <KTTable columns={columnsTable} data={tables} pagination />
        {valueTable && <ModalDiningTable key={'ModalDiningTable'} {...{
            valueSection,
            permission,
            dataAllTables,
            valueData: valueTable,
            handleClose: function () {
                setValueTable(undefined)
            },
            handleSave: async function (values: IHospDiningTable, isEdit: boolean) {
                try {
                    const method = isEdit ? 'put' : 'post'
                    const result = await ApiService[method](`/fnb/hospdiningtables${isEdit ? `/${values.id}` : ''}`, changeDataModify(values))
                    popupMessage({ icon: 'success', autoClose: true })
                    setTables(isEdit ? tables.map(m => m.id == values.id ? values : m) : [result.data, ...tables])
                    refetchAllTables()
                } catch (error) {
                    ApiShowError(error)
                }
                setValueTable(undefined)
            }
        }} />}
    </>
}

function ModalDiningTable({ valueSection, valueData, handleClose, handleSave, dataAllTables, permission }: any) {
    const { watch, register, control, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm<IHospDiningTable>({
        resolver: yupResolver(yup.object().shape({
            tableno: yup.string().test('unique', (val, ctx) => {
                if (val && val != valueData?.tableno && dataAllTables.map((m: any) => m.tableno).includes(val)) return ctx.createError()
                return true
            }).required(),
            tablename: yup.string().required(),
            seatcapacity: yup.number().nullable(true).transform(val => val ? Number(val) : null),
            diningtablestatus: yup.string().required(),
        }))
    })

    useEffect(() => {
        if (valueData) reset({ ...valueData, salestype: valueSection?.salestype ?? null })
    }, [valueData])

    // Free, Seated, Occupied, To Be Cleaned
    const optionStatus: any[] = [
        { label: 'Free', value: 'free' },
        { label: 'Seated', value: 'seated' },
        { label: 'Occupied', value: 'occupied' },
        { label: 'To Be Cleaned', value: 'to-be-cleaned' },
    ]

    return <Modal show={valueData != undefined} onHide={handleClose} centered>
        <Modal.Header closeButton>
            <Modal.Title>{valueData?.id ? 'Edit' : 'Add'} Dining Table</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit((data) => handleSave(data, !!valueData?.id))}>
                <KTFormItem title="Section Name">
                    <span>{valueSection.sectionname}</span>
                </KTFormItem>
                <KTFormItem title="Sales Type">
                    <input disabled className={clsx("form-control form-control-sm", { "form-error": errors.salestype })} {...register("salestype")} />
                </KTFormItem>
                <KTFormItem title="Table No.">
                    <input disabled={!!valueData?.id} className={clsx("form-control form-control-sm", { "form-error": errors.tableno })} {...register("tableno")} />
                </KTFormItem>
                <KTFormItem title="Table Name">
                    <input className={clsx("form-control form-control-sm", { "form-error": errors.tablename })} {...register("tablename")} />
                </KTFormItem>
                <KTFormItem title="Status">
                    <Controller
                        control={control}
                        name="diningtablestatus"
                        render={({ field: { value, onChange } }) => <InputSelect
                            className={clsx("w-100", { "form-error": errors.diningtablestatus })}
                            options={optionStatus}
                            value={optionStatus.find(f => f.value == value)}
                            onChange={(e) => {
                                onChange(e.value)
                            }}
                        />}
                    />
                </KTFormItem>
                <KTFormItem title="Seat Capacity">
                    <input className={clsx("form-control form-control-sm", { "form-error": errors.seatcapacity })} {...register("seatcapacity")} type="number" />
                </KTFormItem>
                <KTFormItem title="Active">
                    <label className="form-check form-check-sm form-check-custom">
                        <input className="form-check-input" type="checkbox" {...register('active')} />
                    </label>
                </KTFormItem>
                <div className="mt-6 d-flex flex-stack">
                    <div />
                    <FormSubmit
                        type={!!valueData?.id}
                        isSubmitting={isSubmitting}
                        handleClose={handleClose}
                    />
                </div>
            </form>
        </Modal.Body>
    </Modal >
}