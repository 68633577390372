import clsx from "clsx"
import { FC } from "react"
import { getIsPermission } from "../../../../app/modules/auth/Permission"

interface PropsBtnSubmit {
    closeWhenSubmit?: boolean
    handleSubmit?: () => void
    hideSubmit?: boolean
    type: "insert" | "modify" | boolean
    align?: "left" | "center" | "right",
    permission?: string,
    isSubmitting: boolean,
    handleClose: () => void
    disabled?: boolean
    refSaveBtn?: any
}

export const FormSubmit: FC<PropsBtnSubmit> = ({ closeWhenSubmit, handleSubmit, hideSubmit, type, align, permission, isSubmitting, handleClose, disabled, refSaveBtn }) => {
    const isSuccess = permission
        ? getIsPermission([permission, typeof type == 'string' ? type.toUpperCase() : (type ? 'UPDATE' : 'CREATE')])
        : true

    return <div className={clsx("d-flex", align ? `justify-content-${align}` : 'justify-content-end')}>
        <button className="btn btn-light-gray border border-2 border-gray btn-sm me-2 btn-custom" type="button" onClick={handleClose}>
            <i className="bi bi-x-lg"></i> Cancel
        </button>
        {!hideSubmit && isSuccess && <>
            <button
                onClick={handleSubmit}
                className={clsx("btn btn-light-orange border border-2 border-orange btn-sm btn-custom", { 'disabled': isSubmitting || disabled })}
                disabled={isSubmitting || disabled}
                ref={refSaveBtn}
                type="submit">
                <i className="bi bi-check-lg fs-5"></i>
                Submit
            </button>
            {closeWhenSubmit && <button
                onClick={() => {
                    handleSubmit && handleSubmit()
                    closeWhenSubmit && handleClose()
                }}
                className={clsx("btn btn-success btn-sm ms-2", { 'disabled': isSubmitting || disabled })}
                disabled={isSubmitting || disabled}
                ref={refSaveBtn}
                type="submit">
                <i className="bi bi-check-lg fs-5"></i>
                Apply
            </button>}
        </>}
    </div >
}