import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { Column } from "react-table"
import { ApiService, formatCurrent } from "../../../theme/helpers"
import { BasicCell, CustomHeader, FilterDropdown, InputSeacrch, KTFormItem, KTTable, PaginationCustom, RowIcon } from "../../../theme/partials"
import { getAuth } from "../../modules/auth"

export interface ITransaction {
    id: string
    code: string
    saletype: string
    receiptno: string | null
    originalreceiptno: string | null
    merchantid: string
    merchantkey: string
    merchantcode: string
    storeno: string
    terminalno: string
    transactionstatus: string
    transactiondate: string
    transactiontime: string
    merchantname: string
    timewhentotalpressed: string | null
    timewhentransactionclosed: string | null
    noofcovers: string | null
    splitnumber: string | null
    staffcode: string | null
    staffreceiptname: string | null
    managerkey: string | null
    managercode: string | null
    managername: string | null
    sectionid: string
    sectioncode: string | null
    sectionname: string
    tableid: string
    tableno: string
    tablename: string
    kitchenstatus: string | null
    memberaccountno: string | null
    membercontactno: string | null
    membercardno: string | null
    noofitems: string | null
    noofitemlines: string | null
    netamount: string
    taxamount: string
    grossamount: string
    totaldiscount: string | null
    totallinediscount: string | null
    discountamount: string
    customerinfo: any
    createdat: string
    createdby: string | null
    modifiedat: string
    modifiedby: string | null
    discountlines: any[]
    hospitalitytype: string
    message: string | null
    coupon: any[]
    einvoice: string | null
    lockinfo: string | null
    paid: boolean
    HospTransactionLines: IHospTransactionLine[]
    TransactionPaymentEntries: ITransactionPaymentEntries[]
}

interface IHospTransactionLine {
    id: string
    storeno: string
    terminalno: string
    transactiondate: string
    transactiontime: string
    hosptransactionid: string
    receiptno: string | null
    lineno: number
    isprinterror: false
    entrytype: string
    itemno: string
    barcodeno: string | null
    variantcode: string | null
    description: string
    categorycode: string | null
    subcategorycode: string | null
    unitofmeasure: string
    quantity: string
    price: string
    netprice: string
    vat: string
    netamount: string
    vatamount: string
    amount: string
    discount: string
    discountamount: string
    linewasdiscounted: string | null
    couponqtyused: string | null
    coupondiscountpercent: string | null
    coupondiscountamount: string | null
    markedforitembenefit: false
    itemname: string
    customizations: []
    kitchenstatus: string
    message: string
    ispaid: boolean | null
    couponcode: string | null
    HospItem: {
        productionsections: { [key: number]: string }
    }
    staffcode: string | null
    kotline: IKotLine | null
}

interface IKotLine {
    lineno: number
    itemno: string
    itemdescription: string
    quantity: string
    uom: string
    category: string
    subcategory: string
    islineprinted: boolean
    isvoided: boolean
    datetimevoided: string | null
    fireddatetime: string | null
    serveddatetime: string | null
    message: string | null
    moreinfo: string | null
    statuscode: number
    modifiedby: string
    modifier: string
    createdby: string
    creater: string
    modifiedat: string
    createdat: string
}

interface ITransactionPaymentEntries {
    id: string
    lineno: number
    transactionid: string
    transactioncode: string
    tendertype: string
    tendertypename: string
    ischangeline: boolean
    amounttendered: string
    paymentdate: string
    paymenttime: string
    merchantcode: string
    ismanagerskey: boolean
    ipn: {
        partnerCode: string
        orderId: string
        requestId: string
        amount: number
        orderInfo: string
        orderType: string
        transId: number
        resultCode: number
        message: string
        payType: string
        responseTime: number
        extraData: string
        signature: string
    }
    staffcode: string | null
    customerno: string
}

export default function NewTransactions() {
    const [merchant, setMerchant] = useState<any>(null)
    const [keyword, setKeyword] = useState<string>('')
    const [pagination, setPagination] = useState({ page: 0, pageSize: 50 })
    const urlString = useMemo(() => (
        `/smarthub/hosptransactions/${merchant?.id}/allday?transactionstatus=Open&expand=TransactionPaymentEntries&page=${pagination.page + 1}&pagesize=${pagination.pageSize}`
    ), [merchant, pagination])
    const { data: transactionsData } = useQuery([urlString], () => ApiService(urlString).then(res => res.data), { enabled: !!merchant?.id })
    const items: ITransaction[] = transactionsData?.items || []
    const total: number = transactionsData?.total || 0

    useEffect(() => {
        const getAuthData = async () => {
            const authData: any = (await getAuth())?.user || {}
            const _merchant = await ApiService(`/fnb/merchants/${authData.store.merchantid}`)
            setMerchant(_merchant.data)
        }
        getAuthData()
    }, [])

    const columns: Array<Column<ITransaction>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='Ngày' />,
        accessor: 'transactiondate',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].transactiondate} className="text-nowrap" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Thời gian' className="text-nowrap" />,
        accessor: 'transactiontime',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].transactiontime} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Mã giao dịch' className="text-nowrap" />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].code} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Bàn' />,
        accessor: 'tablename',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].tablename} className="text-nowrap" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Mã receipt' />,
        accessor: 'receiptno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].receiptno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Tổng cộng' className="text-nowrap" />,
        accessor: 'grossamount',
        Cell: ({ ...props }) => <BasicCell data={formatCurrent(props.data[props.row.index].grossamount)} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Tiền trước thuế' className="text-nowrap" />,
        accessor: 'netamount',
        Cell: ({ ...props }) => <BasicCell data={formatCurrent(props.data[props.row.index].netamount)} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Thuế' />,
        accessor: 'taxamount',
        Cell: ({ ...props }) => <BasicCell data={formatCurrent(props.data[props.row.index].taxamount)} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Giảm giá' className="text-nowrap" />,
        accessor: 'discountamount',
        Cell: ({ ...props }) => <BasicCell data={formatCurrent(props.data[props.row.index].discountamount)} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Nhân viên' className="text-nowrap" />,
        accessor: 'staffcode',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].staffcode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Hành động' className='text-end text-nowrap' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" to={`/transactions/detail/${props.data[props.row.index].id}`} />
        </div>
    }]

    const [filter, setFilter] = useState<ITransaction | undefined>()
    const { register, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: ITransaction[]) => {
        if (!filter) return data
        return data
        // .filter(f => {
        //     const checks = [
        //         f.active == filter.active,
        //     ]
        //     return checks.every(val => val == true)
        // })
    }

    return <div className="card card-body mt-12">
        <div className="d-flex align-items-center justify-content-between mb-5">
            <InputSeacrch
                handleChange={function (e: string): void {
                    setKeyword(e)
                }}
            />
            <FilterDropdown
                handleSubmit={handleSubmit}
                onClose={() => {
                    setFilter(undefined)
                    reset({})
                }}
                onSubmit={(data) => setFilter(data)}>
                <KTFormItem title='Active' labelWidth="auto">
                    <label className="form-check form-switch form-check-custom">
                        <input className="form-check-input" type="checkbox" {...register("active")} />
                    </label>
                </KTFormItem>
            </FilterDropdown>
        </div>
        <KTTable
            overflowX
            data={items}
            columns={columns}
        />
        <PaginationCustom
            pageSize={pagination.pageSize}
            page={pagination.page}
            totalData={total}
            gotoPage={function (pageIndex: number): void {
                setPagination({ ...pagination, page: pageIndex })
            }}
            setPageSize={function (pageSize: number): void {
                setPagination({ ...pagination, pageSize })
            }}
        />
    </div>
}