import clsx from 'clsx';
import React, { ReactNode, useEffect } from 'react';
import { Column, ColumnInstance, IdType, Row, SortingRule, useBlockLayout, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { PaginationCustom } from './pagination';
import { InputSeacrch, removeVietnameseTones } from './search';

interface Props {
  title?: string | ReactNode
  search?: boolean
  toolbar?: ReactNode
  id?: string
  data: Array<object> | any[]
  columns: Array<Column<object>> | any[]
  pagination?: boolean
  overflowX?: boolean
  className?: string
  classWrap?: string
  theadClass?: string
  pageOption?: { page: number; limit: number }
  setPageOption?: (e: { page: number; limit: number }) => void
  rowActive?: Array<number>
  sticky?: boolean
  sortBy?: SortingRule<object>[] | undefined
  cardInnner?: boolean
}

export const KTTable: React.FC<Props> = ({ cardInnner, title, search, toolbar, id, data, overflowX, columns, pagination, className, classWrap, theadClass, pageOption, setPageOption, rowActive, sticky, sortBy }) => {
  const filterTypes = React.useMemo(() => ({
    // Add a new fuzzyTextFilterFn filter type.
    fuzzyText: 'fuzzyTextFilterFn',
    // Or, override the default text filter to use
    // "startWith"
    text: (rows: any, id: any, filterValue: any) => {
      console.log('filterValue', filterValue)
      return rows.filter((row: any) => {
        const rowValue = row.values[id]
        return rowValue !== undefined
          ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
          : true
      })
    },
  }), [])

  const globalFilter = React.useCallback((rows: Row<object>[], ids: IdType<any>[], query: string) => {
    const searchTerm = String(query);

    return rows.filter((row) => {
      const matches = ids.filter((id) => {
        const rowValue = row.values[id];
        let searchTermLower = searchTerm.toLowerCase();

        if (searchTerm == '"') {
          // ignore first ' " ' handle it as no input
          return true
        }
        else if (searchTerm.startsWith('"') && searchTerm.endsWith('"')) {
          const exactSearchTerm = searchTerm.slice(1, -1);
          return String(rowValue) === exactSearchTerm;
        }
        else if (searchTerm.startsWith('"')) {
          // ignore until ending ' " ' has been entered
          searchTermLower = searchTerm.slice(1);
        }
        return rowValue !== undefined
          ? String(removeVietnameseTones(rowValue, true)).includes(removeVietnameseTones(searchTermLower, true).toLowerCase())
          : false;
      });

      return matches.length > 0;
    });
  }, [],)

  const { setGlobalFilter, state, page, setPageSize, gotoPage, getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: { pageSize, pageIndex } } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageOption?.page || 0,
        pageSize: pageOption?.limit && pageOption.limit < data.length ? pageOption.limit : 50,
        sortBy: sortBy || [],
      },
      filterTypes: filterTypes as any,
      globalFilter: globalFilter
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    sticky ? useBlockLayout : () => { },
    useSticky
  )

  const overflowXjs = () => {
    if (document.querySelectorAll('.table-responsive td.cursor-move').length != 0) {
      return
    }
    const slider = document.querySelector('.table-responsive') as any;
    let mouseDown = false;
    let startX = 0 as any;
    let scrollLeft = 0 as any;

    let startDragging = function (e: any) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    let stopDragging = function (event: any) {
      mouseDown = false;
    };

    slider?.addEventListener('mousemove', (e: any) => {
      e.preventDefault();
      if (!mouseDown) { return; }
      const x = e.pageX - slider?.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });
    slider?.addEventListener('mousedown', startDragging, false);
    slider?.addEventListener('mouseup', stopDragging, false);
    slider?.addEventListener('mouseleave', stopDragging, false);
    document.querySelectorAll('.table-responsive td')?.forEach((ele: any) => {
      if (!ele.getAttribute('data-sticky-td')) {
        ele.classList.add("cursor-move")
      }
    })
  }

  if (overflowX) {
    setTimeout(() => {
      overflowXjs()
    }, 50)
  }

  useEffect(() => {
    let ele = document.getElementById('tableKeySearch') as any
    let keyword = ele?.value
    if (keyword && keyword.length != 0) {
      setGlobalFilter(keyword)
    }
  }, [data])

  return <div className={clsx({ 'card card-body': cardInnner })}>
    <div className={clsx(overflowX ? 'table-responsive' : 'table-default', classWrap)} id={id}>
      <div className={clsx('d-flex align-items-center gap-2', { 'mb-3': title || search || toolbar })}>
        {title ? <div className="fs-4 me-auto"> {title} </div> : <></>}
        {search && <InputSeacrch {...{
          className: clsx({ "me-auto": title == undefined }),
          value: state.globalFilter,
          handleChange: setGlobalFilter,
        }} />
        }
        {toolbar ? <div className='d-flex align-items-center gap-2'>{toolbar}</div> : <></>}
      </div>
      <table {...getTableProps()} className={clsx(`table table-row-bordered table-hover dataTable no-footer ${className}`, sticky && 'sticky')} >
        <thead className={theadClass}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className='text-start fw-bold text-dark gs-0' >
              {headerGroup.headers.map((column: ColumnInstance<object>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length > 0 ? page.map((row: Row<object>, i) => {
            prepareRow(row)
            return <CustomRow active={rowActive?.includes(i)} row={row} key={`row-${i}-${row.id}`} />
          })
            : <tr>
              <td colSpan={20}>
                <div className='d-flex w-100 justify-content-center py-2 text-gray-600'>
                  Not found data
                </div>
              </td>
            </tr>}
        </tbody>
      </table>
    </div>
    {pagination && <PaginationCustom
      className='pt-3'
      setPageOption={setPageOption}
      totalData={rows.length}
      pageSize={pageSize}
      page={pageIndex}
      gotoPage={gotoPage}
      setPageSize={setPageSize}
    />
    }
  </div>
}

const CustomHeaderColumn: React.FC<{ column: ColumnInstance<object> }> = ({ column }) => <>
  {column.Header && typeof column.Header === 'string'
    ? <th
      {...column.getHeaderProps(column.getSortByToggleProps())}
      className={clsx(column.isSorted && `table-sort-${column.isSortedDesc ? 'desc' : 'asc'}`)}
    >
      {column.render('Header')}
    </th>
    : column.render('Header')
  }
</>

const CustomRow: React.FC<{ row: Row<object>, active?: boolean }> = ({ row, active }) => (
  <tr {...row.getRowProps()} className={active ? 'bg-light-primary' : ''}>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({ 'text-end': cell.column.id === 'actions', 'bg-light': cell.column.id == 'now' })}
        >
          {cell.render('Cell')}
        </td>
      )
    })}
  </tr>
)
