import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { Column } from "react-table"
import { object, string } from "yup"
import { ApiService, ApiShowError } from "../../../theme/helpers"
import { BasicCell, CustomHeader, FilterDropdown, InputSelect, KTButton, KTFormItem, KTTable, RowIcon } from "../../../theme/partials"
import { popupMessage, popupQuestion } from "../../modules/messages"
import { getAuth } from "../../modules/auth"

interface ICurrentAvailability {
    id?: string
    hospitemid: string | null
    hospitemno: string | null
    hospitemname: string | null
    unitofmeasure: string | null
    merchantid: string | null
    merchantcode: string | null
    defaultqty: string | null
    minqty: string | null
    availableqty: string | null
}

export default function ConfigurationCurrentAvailability() {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { data = [] } = useQuery<ICurrentAvailability[]>([`fnb/currentavailabilities`], () => ApiService.get(`fnb/currentavailabilities`).then(res => res.data))

    const columns: Array<Column<ICurrentAvailability>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='Mã sản phẩm' />,
        accessor: 'hospitemno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].hospitemno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Tên sản phẩm' />,
        accessor: 'hospitemname',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].hospitemname} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Đơn vị tính' />,
        accessor: 'unitofmeasure',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].unitofmeasure} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Số lượng mặc định' />,
        accessor: 'defaultqty',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].defaultqty} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Số lượng tối thiểu' />,
        accessor: 'minqty',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].minqty} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Số lượng hiện có' />,
        accessor: 'availableqty',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].availableqty} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Hành động' className='text-end' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" to={props.data[props.row.index].id} />
            <RowIcon action="delete" onClick={() => handleDelete(props.data[props.row.index].id)} />
        </div>
    }]

    async function handleDelete(id: number) {
        const ques = await popupQuestion({ description: 'Bạn có chắc chắn muốn xóa không?' })
        if (!ques) return;
        try {
            await ApiService.delete(`fnb/currentavailabilities/${id}`)
            queryClient.setQueryData([`fnb/currentavailabilities`], (data || []).filter((item: any) => item.id !== id))
        } catch (error) {
            ApiShowError(error)
        }
    }

    const [filter, setFilter] = useState<ICurrentAvailability | undefined>()
    const { register, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: ICurrentAvailability[]) => {
        if (!filter) return data
        return data
        // .filter(f => {
        //     const checks = [
        //         f.active == filter.active,
        //     ]
        //     return checks.every(val => val == true)
        // })
    }

    return <>
        <div className="my-6 fs-4 fw-bold">Current Availability</div>
        <div className="card card-body">
            <KTTable
                toolbar={<>
                    <div className="d-flex align-items-center gap-2">
                        <FilterDropdown
                            handleSubmit={handleSubmit}
                            onClose={() => {
                                setFilter(undefined)
                                reset({})
                            }}
                            onSubmit={(data) => setFilter(data)}>
                            <KTFormItem title='Active' labelWidth="auto">
                                <label className="form-check form-switch form-check-custom">
                                    <input className="form-check-input" type="checkbox" {...register("active")} />
                                </label>
                            </KTFormItem>

                        </FilterDropdown>
                        <KTButton action="new" onClick={() => navigate('new')} />
                    </div>
                </>}
                columns={columns}
                data={onFilter(data)}
                search
            />
        </div>
    </>
}

export const CurrentAvailabilityDetail = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const isEdit = !!id && id !== 'new'
    const valueId = isEdit ? id : undefined
    const { data, isFetching } = useQuery(`fnb/currentavailabilities/${valueId}`, () => ApiService.get(`fnb/currentavailabilities/${valueId}`).then(res => res.data), { enabled: !!valueId })
    const { register, watch, setValue, reset, handleSubmit, formState: { isSubmitting, errors } } = useForm({
        resolver: yupResolver(
            object().shape({
                hospitemid: string().required(),
                hospitemno: string().required(),
                hospitemname: string().required(),
                unitofmeasure: string().required(),
            })
        )
    })

    const [merchant, setMerchant] = useState<any>(null)

    useEffect(() => {
        const getAuthData = async () => {
            const authData: any = (await getAuth())?.user || {}
            const _merchant = await ApiService(`/fnb/merchants/${authData.store.merchantid}`)
            setMerchant(_merchant.data)
        }
        getAuthData()
    }, [])

    useEffect(() => {
        if (!isFetching && data) {
            reset({ ...data })
        } else {
            reset({})
        }
    }, [isFetching])

    const { data: hospItemsData } = useQuery([`fnb/hospitems`], () => ApiService.get(`fnb/hospitems`).then(res => res.data))
    const optionHospItems = (hospItemsData || []).map((item: any) => ({ ...item, label: `${item.code} ${item.name}`, value: item.id }))

    async function onSubmit(data: any) {
        if (!merchant) {
            popupMessage({ icon: 'error', description: 'Chưa có thông tin merchant' })
            return
        }
        try {
            let bodyData = {
                ...data,
                merchantid: merchant.id,
                merchantcode: merchant.storecode
            }
            await ApiService[isEdit ? 'put' : 'post'](`fnb/currentavailabilities${isEdit ? `/${valueId}` : ''}`, bodyData)
            popupMessage({ icon: 'success', description: isEdit ? 'Cập nhật thành công' : 'Thêm mới thành công' })
            navigate(-1)
        } catch (error) {
            ApiShowError(error)
        }
    }

    return <>
        <form className="card card-body mt-12" onSubmit={handleSubmit(onSubmit, errors => console.log('errors', errors))}>
            <div className="d-flex align-items-center justify-content-between mb-6">
                <div className="fs-4 fw-bold">Current Availability</div>
                <div className="d-flex justify-content-end gap-2">
                    <button type="button" className="btn btn-sm btn-secondary" onClick={() => navigate(-1)}>Quay lại</button>
                    <button type="submit" className="btn btn-sm btn-primary" disabled={isSubmitting}>
                        {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : null}
                        {isEdit ? 'Cập nhật' : 'Thêm mới'}
                    </button>
                </div>
            </div>
            <div className="row mb-6">
                <div className="col-6">
                    <KTFormItem title='Mã sản phẩm'>
                        <InputSelect
                            options={optionHospItems}
                            value={optionHospItems.find((f: any) => f.value == watch('hospitemid'))}
                            onChange={(e) => {
                                setValue('hospitemid', e.value)
                                setValue('hospitemno', e.code)
                                setValue('hospitemname', e.name)
                                setValue('unitofmeasure', e.unitofmeasure)
                            }}
                        />
                    </KTFormItem>
                    <KTFormItem title="Tên sản phẩm">
                        <input className="form-control form-control-sm" {...register("hospitemname")} disabled />
                    </KTFormItem>
                    <KTFormItem title="Đơn vị tính">
                        <input className="form-control form-control-sm" {...register("unitofmeasure")} disabled />
                    </KTFormItem>
                    <KTFormItem title="Số lượng hiện có">
                        <input className="form-control form-control-sm" {...register("availableqty")} />
                    </KTFormItem>
                </div>
                <div className="col-6">
                    <KTFormItem title="ID">
                        <input className="form-control form-control-sm" value={merchant?.storecode} onChange={() => { }} disabled />
                    </KTFormItem>
                    <KTFormItem title="Tên">
                        <input className="form-control form-control-sm" value={merchant?.storename} onChange={() => { }} disabled />
                    </KTFormItem>
                    <KTFormItem title="Số lượng mặt định">
                        <input className="form-control form-control-sm" {...register("defaultqty")} />
                    </KTFormItem>
                    <KTFormItem title="Số lượng tối thiểu">
                        <input className="form-control form-control-sm" {...register("minqty")} />
                    </KTFormItem>
                </div>
            </div>
        </form>
    </>
}