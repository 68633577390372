
import { getAuth } from "../modules/auth"

export function getLocationData() {
  return { title: '', permission: '' }
}

export interface SideBarMenuItem {
  title: string
  path: string
  permission: string
  icon?: string
  children?: SideBarMenuItem[],
  defaultOpen?: boolean
  isLink?: boolean
}
// export const sideBarMenuData = (): SideBarMenuItem[] => {
export async function SideBarMenuData() {
  const localAuth: any = (await getAuth() as any) || {}

  let dataMenu = sideBarMenuData
  let indexMode = dataMenu.findIndex((i: any) => i.path == 'mode')
  if (indexMode > -1) {
    let mode = dataMenu[indexMode]
    let childrenMode = mode.children as any
    if (localAuth.user) {
      const currentUser = localAuth.user
      if (currentUser && currentUser.visibleto) {
        let arrVisible = currentUser.visibleto.split(',')
        childrenMode = childrenMode.filter((i: any) => arrVisible.includes(i.path))
        dataMenu[indexMode].children = childrenMode
      }
    }
  }

  return dataMenu
}
export const sideBarMenuData: SideBarMenuItem[] = [
  {
    title: 'Dashboard',
    path: 'dashboard',
    icon: 'bi bi-house',
    permission: ''
  },
  {
    title: 'Mode',
    path: 'mode',
    icon: '/media/icons/custom/request.svg',
    permission: '',
    defaultOpen: true,
    isLink: true,
    children: [
      {
        title: 'Orders Hub',
        path: 'orders-hub',
        permission: '',
      },
      {
        title: 'Table Service',
        path: 'table-service',
        permission: ''
      },
      {
        title: 'Payment Terminal',
        path: 'payment-terminal',
        permission: ''
      },

    ]
  },
  {
    title: 'Transactions',
    path: 'transactions',
    icon: '/media/icons/custom/tnf_order_request.svg',
    permission: '',
    defaultOpen: true,
    isLink: true,
    children: [
      {
        title: 'New',
        path: 'new',
        permission: '',
      },
      {
        title: 'Active',
        path: 'active',
        permission: ''
      },
      {
        title: 'Completed',
        path: 'completed',
        permission: ''
      },
      {
        title: 'Cancelled',
        path: 'cancelled',
        permission: ''
      }
    ]
  }, 
  {
    title: 'Configuration',
    path: 'configuration',
    icon: '/media/icons/menu/configuration.svg',
    permission: '',
    defaultOpen: true,
    isLink: true,
    children: [
      {
        title: 'Table',
        path: 'section-and-table',
        permission: ''
      },
      {
        title: 'QR Code',
        path: 'qr-code',
        permission: ''
      },
      {
        title: 'Weekly Timing',
        path: 'weekly-timing',
        permission: ''
      },
      {
        title: 'Current Availability',
        path: 'current-availability',
        permission: ''
      },
    ]
  }
]

