import { useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { Column } from "react-table"
import { ApiService, formatCurrent } from "../../../theme/helpers"
import { BasicCell, CustomHeader, FilterDropdown, InputSeacrch, KTFormItem, KTTable, PaginationCustom, RowIcon } from "../../../theme/partials"
import { getAuth } from "../../modules/auth"
import { useForm } from "react-hook-form"
import { ITransaction } from "./new"

export default function CompletedTransactions() {
    const [merchant, setMerchant] = useState<any>(null)
    const [keyword, setKeyword] = useState<string>('')
    const [pagination, setPagination] = useState({ page: 0, pageSize: 50 })
    const urlString = useMemo(() => (
        `/smarthub/hosptransactions/${merchant?.id}/allday?transactionstatus=Completed&expand=TransactionPaymentEntries&page=${pagination.page + 1}&pagesize=${pagination.pageSize}`
    ), [merchant, pagination])
    const { data: transactionsData } = useQuery([urlString], () => ApiService(urlString).then(res => res.data), { enabled: !!merchant?.id })
    const items: ITransaction[] = transactionsData?.items || []
    const total: number = transactionsData?.total || 0

    useEffect(() => {
        const getAuthData = async () => {
            const authData: any = (await getAuth())?.user || {}
            const _merchant = await ApiService(`/fnb/merchants/${authData.store.merchantid}`)
            setMerchant(_merchant.data)
        }
        getAuthData()
    }, [])

    const columns: Array<Column<ITransaction>> = [{
        Header: (props) => <CustomHeader tableProps={props} title='Ngày' />,
        accessor: 'transactiondate',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].transactiondate} className="text-nowrap" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Thời gian' className="text-nowrap" />,
        accessor: 'transactiontime',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].transactiontime} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Mã giao dịch' className="text-nowrap" />,
        accessor: 'code',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].code} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Bàn' />,
        accessor: 'tablename',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].tablename} className="text-nowrap" />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Mã receipt' />,
        accessor: 'receiptno',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].receiptno} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Tổng cộng' className="text-nowrap" />,
        accessor: 'grossamount',
        Cell: ({ ...props }) => <BasicCell data={formatCurrent(props.data[props.row.index].grossamount)} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Tiền trước thuế' className="text-nowrap" />,
        accessor: 'netamount',
        Cell: ({ ...props }) => <BasicCell data={formatCurrent(props.data[props.row.index].netamount)} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Thuế' />,
        accessor: 'taxamount',
        Cell: ({ ...props }) => <BasicCell data={formatCurrent(props.data[props.row.index].taxamount)} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Giảm giá' className="text-nowrap" />,
        accessor: 'discountamount',
        Cell: ({ ...props }) => <BasicCell data={formatCurrent(props.data[props.row.index].discountamount)} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Nhân viên' className="text-nowrap" />,
        accessor: 'staffcode',
        Cell: ({ ...props }) => <BasicCell data={props.data[props.row.index].staffcode} />,
    }, {
        Header: (props) => <CustomHeader tableProps={props} title='Hành động' className='text-end text-nowrap' />,
        id: 'actions',
        Cell: ({ ...props }) => <div className='d-flex flex-end gap-2'>
            <RowIcon action="modify" to={`/transactions/detail/${props.data[props.row.index].id}`} />
        </div>
    }]

    const [filter, setFilter] = useState<ITransaction | undefined>()
    const { register, control, reset, handleSubmit } = useForm<any>()

    const onFilter = (data: ITransaction[]) => {
        if (!filter) return data
        return data
        // .filter(f => {
        //     const checks = [
        //         f.active == filter.active,
        //     ]
        //     return checks.every(val => val == true)
        // })
    }

    return <div className="card card-body mt-12">
        <div className="d-flex align-items-center justify-content-between mb-5">
            <InputSeacrch
                handleChange={function (e: string): void {
                    setKeyword(e)
                }}
            />
            <FilterDropdown
                handleSubmit={handleSubmit}
                onClose={() => {
                    setFilter(undefined)
                    reset({})
                }}
                onSubmit={(data) => setFilter(data)}>
                <KTFormItem title='Active' labelWidth="auto">
                    <label className="form-check form-switch form-check-custom">
                        <input className="form-check-input" type="checkbox" {...register("active")} />
                    </label>
                </KTFormItem>
            </FilterDropdown>
        </div>
        <KTTable
            overflowX
            data={items}
            columns={columns}
        />
        <PaginationCustom
            pageSize={pagination.pageSize}
            page={pagination.page}
            totalData={total}
            gotoPage={function (pageIndex: number): void {
                setPagination({ ...pagination, page: pageIndex })
            }}
            setPageSize={function (pageSize: number): void {
                setPagination({ ...pagination, pageSize })
            }}
        />
    </div>
}