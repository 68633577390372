import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getAuth } from '../../app/modules/auth';
import { isAuth } from '../../app/router/AppRoutes';
import { clearUserData, getCookie, setCookie } from './utils';
export const ApiService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
  },
  timeout: 60000,
})

// ApiService.defaults.baseURL = process.env.REACT_APP_API_URL;
// ApiService.defaults.timeout = 120 * 1000

ApiService.interceptors.request.use(async (config: any) => {
  // const auth = getAuth() as any
  const token = getCookie('token')

  if (token) {
    config.headers.Authorization = `bearer ${token}`
    config.headers['Access-Control-Max-Age'] = 86400
  }
  // console.log('config',config)
  return config;
}, (error) => Promise.reject(error));

type IOriginalRequest = InternalAxiosRequestConfig<any> & { _retry?: boolean } | undefined
let isRefreshing = false;
const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.log('onResponse',response)
  return response;
}
let numRetry = 0

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  let originalRequest: IOriginalRequest = error.config;
  const status = error.response?.status || 0;
  const statuscode = (error.response?.data as any)?.errorCode || 0;
  const errorMessage = (error.response?.data as any)?.errorMessage || ''
  const codeErr = (error as any).code
  // console.log('sdadadsa error',error)
  if (axios.isCancel(error)) {
      // console.log('Request cancel', error.message);
      return Promise.reject(error);
  }
  // let apiLog = 'smarthub/logs/write'
  // if(codeErr == "ECONNABORTED" && originalRequest && originalRequest.url != apiLog) { 
  //   if(numRetry < 1) {
  //     try {
  //       if(numRetry < 1) {
  //         numRetry = numRetry + 1 
  //         let tempData = {
  //           funcName: 'Retry api '+ originalRequest.url,
  //           error: 'Time out',
  //         }
  //         ApiService.post(`smarthub/logs/write`, tempData)

  //         return ApiService(originalRequest);
  //       }
  //       else {
  //         numRetry = 0
  //         return Promise.reject(error);
  //       }
  //     } catch (error) {
  //         return Promise.reject(error);
  //     }
  //   }
  //   else {
  //     numRetry = 0
  //     return Promise.reject(error);

  //   }
   
  // }
  // Kiểm tra mã lỗi
  if (status == 403 && originalRequest && !isRefreshing && statuscode != '403-5000') {
      console.log('statuscode',statuscode)
      if ((statuscode !== '403-284210010' && statuscode !== '403-284210000') || errorMessage.includes('Token Revoked')) {
          clearUserData()
          // console.log('aaaaaaaaaa')
          // window.location.href = '/login'
          return Promise.reject(error);
      }
      isRefreshing = true;
      const token = getCookie('token')

      console.log('token',token)

      if (!token) {
        clearUserData()
        return Promise.reject(error);
      }
      try {
          // Gọi API để lấy token mới
          // const refresh = await isAuth()
          console.log('call authen')
          const { isAuthenticated, newAccessToken } = await isAuth()||{}
          
        // if (newAccessToken && isAuthenticated) await saveAuth({ ...auth, token: newAccessToken })
        if (!isAuthenticated) {
          clearUserData()
         }
          const newToken = newAccessToken
          if (!newToken) {
              return Promise.reject(error);
          }

          setCookie('token', newToken, 365)
          ApiService.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          // Call lại request đã bị lỗi
          return ApiService(originalRequest);
      } catch (error) {
          clearUserData()
          return Promise.reject(error);
      } finally {
          isRefreshing = false;
      }
  }
  
  // Nếu không, trả lỗi về điểm cuối đã gọi api
  // console.log('error',error)
  if(codeErr == 'ERR_NETWORK') {
    let tempError = { data: null, error: "Không có kết nối internet!", status: 0 }
    // console.log('tempError',tempError)
    return Promise.reject(tempError);
  }
  return Promise.reject(error);
}
ApiService.interceptors.response.use(onResponse, onResponseError)

